import { Layout, Modal } from 'antd';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../../shared/Breadcrumbs/Breadcrumbs';
import PrivateRoute from '../../shared/PrivateRoute';
import TimezonePicker from '../../shared/TimezonePicker/TimezonePicker';
import HeaderComponent from '../Header/Header';
import SideMenu from '../SideMenu/SideMenu';
import './Dashboard.less';
import { routes } from '../../../routes';
import { getTimezone } from '../../../store/slices/app/selectors';
import { getDeviceSettings } from '../../../store/slices/codes/extraReducers';

const { Content } = Layout;

const Dashboard = () => {
  const dispatch = useDispatch();
  const timezone = useSelector(getTimezone);

  useEffect(() => {
    dispatch(getDeviceSettings());
  }, []);

  return (
    <Layout style={{ height: '100vh' }}>
      {!timezone && (
        <Modal title='Select your timezone' visible footer={null} closable={false}>
          <TimezonePicker />
        </Modal>
      )}

      <SideMenu />
      <Layout>
        <HeaderComponent />
        <Content style={{ overflow: 'scroll' }} className='content-wrapper'>
          <div className='flex-between flex-align-center p-0-25'>
            <Breadcrumbs />
            <div className='timezone-wrapper'>
              <TimezonePicker />
            </div>
          </div>
          <div className='main-container' id='main-container'>
            <Switch>
              {routes.map((route) => {
                return (
                  <PrivateRoute
                    exact
                    roles={route.roles}
                    path={route.path}
                    component={route.component}
                    key={route.path}
                  />
                );
              })}
            </Switch>
            <Route path='/' exact>
              <Redirect to='/home' />
            </Route>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
