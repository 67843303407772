import { SortDirection } from '../../enum/SortDirection';

export interface Page {
  currentPage: number;
  size: number;
  totalElements: number;
  totalPages: number;
  sortDirection: SortDirection;
  sortProperty: string;
}

export const pageInitialState: Page = {
  currentPage: 0,
  size: 10,
  totalElements: 0,
  totalPages: 0,
  sortDirection: SortDirection.DESC,
  sortProperty: 'id',
};
