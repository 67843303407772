import React, { useMemo } from 'react';
import { Progress, Typography } from 'antd';
import { ScheduledDevice } from '../model/interfaces/student';

const { Title } = Typography;

interface LabLoaderProps {
  percentage: number;
  message?: string;
  devices: Array<ScheduledDevice>;
}

const LabLoader = ({ percentage, message = 'Loading lab...', devices }: LabLoaderProps) => {
  const status: any = useMemo(() => {
    if (devices) {
      if (percentage === 100) {
        return 'success';
      }
      if (percentage === -1) {
        return 'exception';
      }
    }
    return 'active';
  }, [devices, percentage]);

  return (
    <div className='flex-center flex-column' style={{ padding: '20px' }}>
      <Title style={{ textAlign: 'center' }} level={5}>
        {message}
      </Title>
      <Progress
        type='circle'
        percent={percentage === -1 ? 100 : percentage}
        status={status}
        className='flex-center'
      />
    </div>
  );
};

export default LabLoader;
