import { createAsyncThunk } from '@reduxjs/toolkit';
import { BasePageRequest } from '../../../model/interfaces/shared/basePageRequest';
import { UCDeviceFilterRequest } from '../../../model/interfaces/ucDevice';
import { filterUCDeviceApi } from '../../../service/UCDeviceService';
import { UCDeviceState } from '../../../model/interfaces/ucDevice';
import { Status } from '../../../model/enum/Status';

export const filterUCDevice = createAsyncThunk(
  'getUCDevice',
  async (command: BasePageRequest<UCDeviceFilterRequest>) => {
    const response = await filterUCDeviceApi(command);
    return response.data;
  }
);

export const extraReducers = (builder: any) => {
  builder.addCase(filterUCDevice.pending, (state: UCDeviceState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(filterUCDevice.fulfilled, (state: UCDeviceState, action: any) => {
    state.status = Status.SUCCESS;
    state.page = action.payload.page;
    state.data = action.payload.response;
  });
  builder.addCase(filterUCDevice.rejected, (state: UCDeviceState) => {
    state.status = Status.ERROR;
    state.data = [];
  });
};
