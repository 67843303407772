import React, { useEffect, useState } from 'react';
import { Button, Input, Popconfirm, Table } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentPage,
  getData,
  getPage,
  getRequest,
  getStatus,
} from '../../store/slices/token/selectors';
import { checkCurrentPage, getDefaultSort } from '../../util/helperUtils';
import useNotification from '../../hooks/useNotification';

import HttpMethod from '../../model/enum/HttpMethod';
import './Tokens.css';
import { Tooltip } from 'antd';
import useAxios from 'hooks/useAxios';
import { filterTokenLabs } from 'store/slices/token/extraReducers';
import { itemRender } from '../../components/shared/TableItemRender';
import { TableActions } from 'model/enum/TableActions';
import { tokenActions } from 'store/slices/token/state';
import { Link } from 'react-router-dom';
import { Status } from 'model/enum/Status';
import useTimezone from 'hooks/useTimezone';
import TokenNewLabModal from 'components/modal/TokenNewLabModal';

const { Search } = Input;

const Tokens = () => {
  const dispatch = useDispatch();
  const { successNotification } = useNotification();
  const data = useSelector(getData);
  const page = useSelector(getPage);
  const status = useSelector(getStatus);
  const request = useSelector(getRequest);
  const currentPage = useSelector(getCurrentPage);
  const { result, loading, run, param } = useAxios();
  const [tokenId, setTokenId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const { addTimezoneOnDate, newDate } = useTimezone();

  useEffect(() => {
    if (result) {
      fetchData(true);
    }
  }, [result]);

  useEffect(() => {
    fetchData();
  }, [request, currentPage, page.sortProperty, page.sortDirection]);

  const fetchData = (itemDeleted?: boolean) => {
    if (itemDeleted) {
      successNotification('Token deleted');
    }
    dispatch(
      filterTokenLabs({
        request: {
          ...request,
        },
        ...page,
        currentPage: checkCurrentPage(page, itemDeleted),
      })
    );
  };

  const openModal = () => {
    setStartDate(newDate().startOf('day').toISOString());
    setEndDate(newDate().add(1, 'day').startOf('day').toISOString());
    setShowModal(true);
  };

  const editModal = (tokenId: any) => {
    setStartDate(newDate().startOf('day').toISOString());
    setEndDate(newDate().add(1, 'day').startOf('day').toISOString());
    setTokenId(tokenId);
    setShowModal(true);
  };

  const closeModal = (refresh: boolean) => {
    setShowModal(false);
    setTokenId(null);
    if (refresh) {
      fetchData();
    }
  };

  const columns: any = [
    {
      title: 'Student',
      dataIndex: 'studentName',
      key: 'studentName',
      sorter: true,
      defaultSortOrder: getDefaultSort(page, 'studentName'),
    },
    {
      title: 'Custom Name',
      dataIndex: 'customName',
      key: 'customName',
      sorter: true,
      defaultSortOrder: getDefaultSort(page, 'customName'),
    },
    {
      title: 'Lab Bundle',
      dataIndex: 'labBundle',
      key: 'labBundle',
      sorter: true,
      defaultSortOrder: getDefaultSort(page, 'labBundle'),
    },
    {
      title: 'Minutes Remaining',
      dataIndex: 'tokenAmountMinutes',
      key: 'tokenAmountMinutes',
      sorter: true,
      defaultSortOrder: getDefaultSort(page, 'tokenAmountMinutes'),
    },
    {
      title: '',
      key: 'action',
      render: (record: any) => (
        <div onClick={(e) => e.stopPropagation()} className={'flex-center'}>
          {
            // <Link to={`/tokens/edit/${record.id}`}>
            <Button
              onClick={() => editModal(record.id)}
              type='text'
              color='primary'
              shape='circle'
              icon={<EditOutlined />}
            />
            // </Link>
          }
          <Popconfirm
            placement='left'
            title='Are you sure you want to delete this Token?'
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() =>
              run({
                url: `token/delete/`,
                method: HttpMethod.DELETE,
                param: record.id,
              })
            }
            okText='Yes'
            cancelText='No'>
            <Button
              danger
              type='text'
              shape='circle'
              icon={param === record.id && loading ? <LoadingOutlined /> : <DeleteOutlined />}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    switch (extra.action) {
      case TableActions.FILTER:
        dispatch(tokenActions.setRequest(filters));
        break;
      case TableActions.SORT:
        dispatch(tokenActions.setPage({ page, sorter }));
        break;
      case TableActions.PAGINATE:
        dispatch(tokenActions.setCurrentPage(pagination));
        break;
    }
  };

  const onSearch = (search: string) => {
    dispatch(
      tokenActions.setRequest({
        ...request,
        username: search,
      })
    );
  };

  return (
    <>
      {showModal && (
        <TokenNewLabModal
          id={tokenId}
          closeModal={closeModal}
          startDate={newDate().startOf('day').toISOString()}
          endDate={newDate().add(1, 'day').startOf('day').toISOString()}
        />
      )}
      <div className='flex-between m-b-16'>
        <Search
          defaultValue={request?.search}
          placeholder='Search'
          className='max-w-300'
          allowClear
          enterButton
          onSearch={onSearch}
        />

        <Button type='primary' onClick={openModal} className='m-l-8'>
          Create new Token
        </Button>
      </div>
      <Table
        rowKey='id'
        columns={columns}
        dataSource={data}
        pagination={{
          position: ['bottomCenter'],
          size: 'small',
          itemRender,
          current: currentPage + 1,
          pageSize: page?.size,
          total: page?.totalElements,
          showSizeChanger: false,
        }}
        onChange={onChange}
        loading={status === Status.LOADING}
        scroll={{ x: 300, scrollToFirstRowOnChange: true }}
      />
    </>
  );
};

export default Tokens;
