import { apiGet, apiPost } from './Axios';
import { UserSaveRequest } from '../model/interfaces/user';

export const getUserApi = (userId: string) => {
  return apiGet(`user/${userId}`);
};

export const updateUserApi = (request: UserSaveRequest) => {
  return apiPost(`user/update`, request);
};
