import { RootState } from '../../index';

export const getStatus = (state: RootState) => {
  return state.student.status;
};

export const getCurrentPage = (state: RootState) => {
  return state.student.page.currentPage;
};

export const getPage = (state: RootState) => {
  return state.student.page;
};

export const getRequest = (state: RootState) => {
  return state.student.request;
};

export const getData = (state: RootState) => {
  return state.student.data;
};

export const getDate = (state: RootState) => {
  return state.student.date;
};
