import React from 'react';
import { ConfigProvider } from 'antd';
import enGb from 'antd/lib/locale/en_GB';

const AntdConfigProvider = ({ children }: { children: any }) => {
  ConfigProvider.config({
    theme: {
      primaryColor: '#175D5C',
    },
  });

  return <ConfigProvider locale={enGb}>{children}</ConfigProvider>;
};

export default AntdConfigProvider;
