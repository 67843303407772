import React, { useEffect, useState } from 'react';
import { Button, Card, InputNumber, Select, Space, Spin } from 'antd';
import { Calendar } from '../../components/shared/Calendar/Calendar';
import { useDispatch } from 'react-redux';
import { getLabBundlesCodes } from '../../store/slices/codes/extraReducers';
import ScheduleNewLabModal from '../../components/modal/ScheduleNewLabModal';
import { filterOption } from '../../util/helperUtils';
import useAxios from '../../hooks/useAxios';
import HttpMethod from '../../model/enum/HttpMethod';
import useTimezone from '../../hooks/useTimezone';
import { LabBundle } from '../../model/interfaces/labBundle';

const { Option } = Select;

const Availability = () => {
  const dispatch = useDispatch();
  const { addTimezoneOnDate, newDate } = useTimezone();
  const [showModal, setShowModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [numberOfStudents, setNumberOfStudents] = useState(1);
  const [labBundleId, setLabBundleId] = useState();
  const [scheduleId, setScheduleId] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateUntil, setDateUntil] = useState();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const { result, loading, run } = useAxios();
  const { result: labBundles, loading: loadingLabBundles, run: getLabBundles } = useAxios();
  const {
    result: maxNumberOfStudents,
    loading: loadingMaxNumberOfStudents,
    run: getMaxNumberOfStudents,
  } = useAxios();

  useEffect(() => {
    dispatch(getLabBundlesCodes());
    getLabBundles({
      url: 'lab-bundle/all',
    });
  }, []);

  useEffect(() => {
    if (result) {
      const preparedEvents = result.map((e: any) => {
        let endDate = addTimezoneOnDate(e.end);
        return {
          ...e,
          start: addTimezoneOnDate(e.start).toISOString(),
          end: endDate.toISOString(),
          title: e.customName ? `${e.customName} (${e.title})` : e.title,
        };
      });
      setEvents(preparedEvents);
    }
  }, [result]);

  useEffect(() => {
    refreshCalendar();
  }, [dateFrom, dateUntil, numberOfStudents, labBundleId]);

  const refreshCalendar = () => {
    if (labBundleId && labBundles && numberOfStudents > 0) {
      const labBundle = labBundles.find((lb: LabBundle) => lb.id === labBundleId);
      const numberOfWorkgroups = Math.ceil(numberOfStudents / labBundle.studentsPerWorkgroup);
      run({
        url: 'schedule/calendar/occupied',
        method: HttpMethod.POST,
        body: {
          labBundleId,
          dateFrom,
          dateUntil,
          numberOfWorkgroups,
        },
      });
    }
  };

  const onDatesChange = (info: any) => {
    setDateFrom(info.view.currentStart.toISOString());
    setDateUntil(info.view.currentEnd.toISOString());
  };

  const openModal = () => {
    setStartDate(newDate().startOf('day').toISOString());
    setEndDate(newDate().add(1, 'day').startOf('day').toISOString());
    setShowModal(true);
  };

  const onCalendarSelect = (value: any) => {
    setStartDate(newDate(value.start).startOf('day').toISOString());
    setEndDate(newDate(value.end).startOf('day').toISOString());
    setShowModal(true);
  };

  const closeModal = (refresh: boolean) => {
    setShowModal(false);
    setScheduleId(null);
    if (refresh) {
      refreshCalendar();
    }
  };

  const selectLabBundle = (id: any) => {
    setLabBundleId(id);
    getMaxNumberOfStudents({
      url: 'lab/max-number-of-students/' + id,
    });
  };

  const labBundlesOptions = labBundles?.map((lb: LabBundle) => {
    return (
      <Option key={lb.id} value={lb.id}>
        {lb.description}
      </Option>
    );
  });

  const changeNumberOfStudents = (value: number) => {
    setNumberOfStudents(value);
  };

  return (
    <>
      {showModal && (
        <ScheduleNewLabModal
          labBundleId={labBundleId}
          students={numberOfStudents}
          id={scheduleId}
          closeModal={closeModal}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      <Card className='filter-card m-b-8'>
        <div className='flex-between flex-wrap gap-8'>
          <Space>
            <div>Number of students</div>
            <InputNumber
              min={1}
              max={maxNumberOfStudents}
              style={{ width: 50 }}
              onChange={changeNumberOfStudents}
              value={numberOfStudents}
            />
            <Select
              style={{ minWidth: 180 }}
              showSearch
              placeholder='Select lab bundle'
              loading={loadingLabBundles || loadingMaxNumberOfStudents}
              onSelect={selectLabBundle}
              value={labBundleId}
              filterOption={filterOption}>
              {labBundlesOptions}
            </Select>
          </Space>
          <Button onClick={openModal} type='primary'>
            Schedule a new lab
          </Button>
        </div>
      </Card>
      <Card>
        <Spin spinning={loading}>
          <div className='calendar-container'>
            <Calendar
              eventColor='#b2283c'
              events={events}
              datesSet={onDatesChange}
              selectable={true}
              select={onCalendarSelect}
            />
          </div>
        </Spin>
      </Card>
    </>
  );
};

export default Availability;
