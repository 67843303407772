export const GUACAMOLE_CLIENT_STATES: any = {
  STATE_IDLE: 0,
  STATE_CONNECTING: 1,
  STATE_WAITING: 2,
  STATE_CONNECTED: 3,
  STATE_DISCONNECTING: 4,
  STATE_DISCONNECTED: 5,
};

export const GUACAMOLE_STATUS: any = {
  0: {
    name: 'SUCCESS',
    text: 'The operation succeeded. No error.',
  },
  256: {
    name: 'UNSUPPORTED',
    text: 'The requested operation is unsupported.',
  },
  512: {
    name: 'SERVER_ERROR',
    text: 'An internal error occurred, and the operation could not be performed.',
  },
  513: {
    name: 'SERVER_BUSY',
    text: 'The operation could not be performed because the server is busy.',
  },
  514: {
    name: 'UPSTREAM_TIMEOUT',
    text: 'The upstream server is not responding.',
  },
  515: {
    name: 'UPSTREAM_ERROR',
    text: 'The upstream server encountered an error.',
  },
  516: {
    name: 'RESOURCE_NOT_FOUND',
    text: 'An associated resource, such as a file or stream, could not be found, and thus the operation failed.',
  },
  517: {
    name: 'RESOURCE_CONFLICT',
    text: 'A resource is already in use or locked, preventing the requested operation.',
  },
  518: {
    name: 'RESOURCE_CLOSED',
    text: 'The requested operation cannot continue because the associated resource has been closed.',
  },
  519: {
    name: 'UPSTREAM_NOT_FOUND',
    text: 'The upstream server does not appear to exist, or cannot be reached over the network.',
  },
  520: {
    name: 'UPSTREAM_UNAVAILABLE',
    text: 'The upstream server is refusing to service connections.',
  },
  521: {
    name: 'SESSION_CONFLICT',
    text: 'The session within the upstream server has ended because it conflicts with another session.',
  },
  522: {
    name: 'SESSION_TIMEOUT',
    text: 'The session within the upstream server has ended because it appeared to be inactive.',
  },
  523: {
    name: 'SESSION_CLOSED',
    text: 'The session within the upstream server has been forcibly closed.',
  },
  768: {
    name: 'CLIENT_BAD_REQUEST',
    text: 'The parameters of the request are illegal or otherwise invalid.',
  },
  769: {
    name: 'CLIENT_UNAUTHORIZED',
    text: 'Permission was denied, because the user is not logged in. Check domain, username and password',
  },
  771: {
    name: 'CLIENT_FORBIDDEN',
    text: 'Permission was denied, and logging in will not solve the problem.',
  },
  776: {
    name: 'CLIENT_TIMEOUT',
    text: 'The client (usually the user of Guacamole or their browser) is taking too long to respond.',
  },
  781: {
    name: 'CLIENT_OVERRUN',
    text: 'The client has sent more data than the protocol allows.',
  },
  783: {
    name: 'CLIENT_BAD_TYPE',
    text: 'The client has sent data of an unexpected or illegal type.',
  },
  797: {
    name: 'CLIENT_TOO_MANY',
    text: 'The client is already using too many resources. Existing resources must be freed before further requests are allowed.',
  },
};
