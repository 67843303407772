import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { appActions } from '../store/slices/app/appState';
import useAxios from './useAxios';

const useAuth = () => {
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const userId = keycloak.subject as string;

  const { result, loading, run } = useAxios();

  useEffect(() => {
    if (initialized && userId) {
      run({ url: `/user/${userId}` });
    }
  }, [userId, initialized]);

  useEffect(() => {
    if (result) {
      dispatch(appActions.setUser(result));
    }
  }, [result, dispatch]);

  return {
    loading,
    initialized,
    userId,
  };
};

export default useAuth;
