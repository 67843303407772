import { createAsyncThunk } from '@reduxjs/toolkit';
import { BasePageRequest } from '../../../model/interfaces/shared/basePageRequest';
import { TokenFilterRequest, TokenState } from '../../../model/interfaces/token';
import { Status } from '../../../model/enum/Status';
import { filterTokenLabsApi } from '../../../service/TokenService';

export const filterTokenLabs = createAsyncThunk(
  'filterTokenLabs',
  async (command: BasePageRequest<TokenFilterRequest>) => {
    const response = await filterTokenLabsApi(command);
    return response.data;
  }
);

export const extraReducers = (builder: any) => {
  builder.addCase(filterTokenLabs.pending, (state: TokenState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(filterTokenLabs.fulfilled, (state: TokenState, action: any) => {
    state.status = Status.SUCCESS;
    state.page = action.payload.page;
    state.data = action.payload.response;
  });
  builder.addCase(filterTokenLabs.rejected, (state: TokenState) => {
    state.status = Status.ERROR;
    state.data = [];
  });
};
