import { createAsyncThunk } from '@reduxjs/toolkit';
import { BasePageRequest } from '../../../model/interfaces/shared/basePageRequest';
import { ScheduleFilterRequest, ScheduleState } from '../../../model/interfaces/schedule';
import { Status } from '../../../model/enum/Status';
import { filterScheduledLabsApi } from '../../../service/ScheduleService';

export const filterScheduledLabs = createAsyncThunk(
  'filterScheduledLabs',
  async (command: BasePageRequest<ScheduleFilterRequest>) => {
    const response = await filterScheduledLabsApi(command);
    return response.data;
  }
);

export const extraReducers = (builder: any) => {
  builder.addCase(filterScheduledLabs.pending, (state: ScheduleState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(filterScheduledLabs.fulfilled, (state: ScheduleState, action: any) => {
    state.status = Status.SUCCESS;
    state.page = action.payload.page;
    state.data = action.payload.response;
  });
  builder.addCase(filterScheduledLabs.rejected, (state: ScheduleState) => {
    state.status = Status.ERROR;
    state.data = [];
  });
};
