import { Modal, Table } from 'antd';
import { ExerciseEquipment } from '../../model/interfaces/exerciseEquipment';

const columns = [
  {
    title: 'Device Name',
    dataIndex: 'exerciseDeviceName',
    key: 'exerciseDeviceName',
  },
  {
    title: 'Logical Name',
    dataIndex: 'logicalName',
    key: 'logicalName',
  },
  {
    title: 'Access Port',
    dataIndex: 'accessPort',
    key: 'accessPort',
  },
  {
    title: 'Access IP',
    dataIndex: 'accessIp',
    key: 'accessIp',
  },
];

const DevicesModal = ({
  exerciseEquipment,
  onCancel,
}: {
  exerciseEquipment: Array<ExerciseEquipment>;
  onCancel: () => void;
}) => {
  return (
    <Modal title='Devices' visible={true} footer={null} onCancel={onCancel} centered>
      <Table rowKey='id' columns={columns} dataSource={exerciseEquipment} pagination={false} />
    </Modal>
  );
};

export default DevicesModal;
