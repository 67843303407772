import { createSlice } from '@reduxjs/toolkit';
import { tableInitialState } from '../../../model/interfaces/shared/tableState';
import { LabTopologyState } from '../../../model/interfaces/labTopology';
import { reducers } from './reducers';
import { extraReducers } from './extraReducers';

const initialState: LabTopologyState = {
  ...tableInitialState,
};

const labTopologySlice = createSlice({
  name: 'lab-topology',
  initialState,
  reducers,
  extraReducers,
});

export default labTopologySlice;

export const labTopologyActions = labTopologySlice.actions;
