import { useState } from 'react';
import HttpMethod from '../model/enum/HttpMethod';
import nilAxios from '../service/Axios';

interface RunAxios {
  url: string;
  method?: HttpMethod;
  body?: any;
  headers?: any;
  param?: string;
}

const useAxios = () => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [param, setParam] = useState(null);

  const run = ({
    url = '',
    method = HttpMethod.GET,
    body = null,
    headers = null,
    param = null,
  }: RunAxios) => {
    setLoading(true);
    setResult(null);
    let newUrl = url;
    if (param) {
      newUrl = url + param;
      setParam(param);
    }

    nilAxios(newUrl, method, body, headers)
      .then((res: any) => {
        setResult(res.data);
      })
      .catch((err: any) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
        setParam(null);
      });
  };

  return {
    result,
    error,
    loading,
    run,
    param,
  };
};

export default useAxios;
