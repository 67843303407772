import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import './Calendar.css';
import { Tooltip } from 'antd';
import React from 'react';
import useTimezone from '../../../hooks/useTimezone';

export const Calendar = (props: any) => {
  const { formatDate } = useTimezone();

  const renderInnerContent = (innerProps: any) => {
    return (
      <div className='fc-daygrid-day-events'>
        <div className='fc-daygrid-event-harness' style={{ marginTop: 0 }}>
          <div className='fc-daygrid-event fc-daygrid-dot-event fc-event-end fc-event-future'>
            <div className='fc-daygrid-event-dot' style={{ borderColor: 'rgb(0, 182, 149)' }} />
            <div className='fc-event-time'>{innerProps.timeText}</div>
            <div className='fc-event-title'>{innerProps.event.title}</div>
          </div>
        </div>
      </div>
    );
  };

  const eventContent = (arg: any) => {
    const tooltipText = (
      <div className='tooltip' style={{ padding: '10px' }}>
        <p>{arg.event.title}</p>
        <p className='m-0'>Start date: {formatDate(arg.event.start)}</p>
        <p>End date: {formatDate(arg.event.end)}</p>
        <p>{arg.event.extendedProps.workgroupDescription}</p>
      </div>
    );

    return <Tooltip title={tooltipText}>{renderInnerContent(arg)}</Tooltip>;
  };

  return (
    <FullCalendar
      eventDisplay='block'
      nextDayThreshold='00:00:01'
      timeZone='none'
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      headerToolbar={{
        center: 'title',
        left: 'dayGridMonth,timeGridWeek,timeGridDay',
      }}
      eventColor='#00b695'
      nowIndicator
      firstDay={1}
      slotEventOverlap={true}
      initialView='dayGridMonth'
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
      }}
      slotLabelFormat={{
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
      }}
      views={{
        timeGridWeek: {
          titleFormat: { year: 'numeric', month: 'long', day: '2-digit' },
        },
      }}
      displayEventEnd={true}
      displayEventTime={true}
      eventContent={eventContent}
      {...props}
    />
  );
};
