import { createAsyncThunk } from '@reduxjs/toolkit';
import { BasePageRequest } from '../../../model/interfaces/shared/basePageRequest';
import { LabTopologyFilterRequest, LabTopologyState } from '../../../model/interfaces/labTopology';
import { filterLabTopologyApi } from '../../../service/LabTopologyService';
import { Status } from '../../../model/enum/Status';

export const filterLabTopology = createAsyncThunk(
  'getLabTopology',
  async (command: BasePageRequest<LabTopologyFilterRequest>) => {
    const response = await filterLabTopologyApi(command);
    return response.data;
  }
);

export const extraReducers = (builder: any) => {
  builder.addCase(filterLabTopology.pending, (state: LabTopologyState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(filterLabTopology.fulfilled, (state: LabTopologyState, action: any) => {
    state.status = Status.SUCCESS;
    state.page = action.payload.page;
    state.data = action.payload.response;
  });
  builder.addCase(filterLabTopology.rejected, (state: LabTopologyState) => {
    state.status = Status.ERROR;
    state.data = [];
  });
};
