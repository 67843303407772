import { createSlice } from '@reduxjs/toolkit';
import { tableInitialState } from '../../../model/interfaces/shared/tableState';
import { TokenState } from '../../../model/interfaces/token';
import { reducers } from './reducers';
import { extraReducers } from './extraReducers';

const initialState: TokenState = {
  ...tableInitialState,
  username: null,
};

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers,
  extraReducers,
});

export default tokenSlice;

export const tokenActions = tokenSlice.actions;
