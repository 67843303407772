import { RootState } from '../../index';

export const getStatus = (state: RootState) => {
  return state.codes.status;
};

export const siDevicesCodes = (state: RootState) => {
  return state.codes.siDevices;
};

export const ucDevicesCodes = (state: RootState) => {
  return state.codes.ucDevices;
};

export const labBundlesCodes = (state: RootState) => {
  return state.codes.labBundles;
};

export const instructorsCodes = (state: RootState) => {
  return state.codes.instructors;
};

export const studentsCodes = (state: RootState) => {
  return state.codes.students;
};

export const deviceSettingsCodes = (state: RootState) => {
  return state.codes.deviceSettings;
};

export const deviceTypeCodes = (state: RootState) => {
  return state.codes.deviceTypes;
};
