import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import PrivateRoute from './components/shared/PrivateRoute';
import useAuth from './hooks/useAuth';
import logo from './assets/images/animated-logo.gif';
import { allRoles } from './model/enum/Roles';
import Dashboard from './components/layout/Dashboard/Dashboard';
import {
  DateFormatCookie,
  RdpConnectionCookie,
  TimeFormatCookie,
  Token,
} from './model/interfaces/app';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from './store/slices/app/appState';
import { scheduleActions } from './store/slices/schedule/state';
import useTimezone from './hooks/useTimezone';
import { getToken } from './store/slices/app/selectors';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { MessageType } from './model/enum/MessageType';
import config from './util/config';

const AppRouter = () => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const { initialized, loading } = useAuth();
  const { newDate } = useTimezone();

  // initial load of app
  useEffect(() => {
    setValuesFromLocalStorage();
    connectToWebsocket();
    dispatch(scheduleActions.setDate(newDate()));
  }, []);

  const setValuesFromLocalStorage = () => {
    const timezone = localStorage.getItem(TimeFormatCookie);
    if (timezone) {
      dispatch(appActions.setTimezone(timezone));
    }
    const timeFormat = localStorage.getItem(TimeFormatCookie);
    if (timeFormat) {
      dispatch(appActions.setTimeFormat(timeFormat));
    }
    const dateFormat = localStorage.getItem(DateFormatCookie);
    if (dateFormat) {
      dispatch(appActions.setDateFormat(dateFormat));
    }
    const rdpConnection = localStorage.getItem(RdpConnectionCookie);
    if (rdpConnection) {
      dispatch(appActions.setRdpConnection(rdpConnection));
    }
    const token = localStorage.getItem(Token);
    if (token) {
      dispatch(appActions.setToken(token));
    }
  };

  const connectToWebsocket = () => {
    let ws = new SockJS(config.websocketHttpUrl);
    let stompClient = Stomp.over(ws);
    stompClient.debug = null;
    stompClient.connect(
      {
        'Auth-Token': 'Bearer ' + token,
      },
      () => {
        stompClient.subscribe('/topic/device', (message: any) => {
          if (message) {
            let response = JSON.parse(message.body);
            if (response.type === MessageType.DEVICE_PROGRESS) {
              dispatch(appActions.setDeviceStatus(response.message));
            }
          }
        });
      }
    );
  };

  if (!initialized || loading) {
    return <img src={logo} alt='logo' className='animated-logo' />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute roles={allRoles} path='/' component={Dashboard} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
