import React, { useCallback, useEffect } from 'react';
import './TimezonePicker.less';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import timezones from '../../../util/timzones';
import { appActions } from '../../../store/slices/app/appState';
import { getTimezone } from '../../../store/slices/app/selectors';
import { TimezoneCookie } from '../../../model/interfaces/app';
import { filterOption } from '../../../util/helperUtils';

const { Option } = Select;

const TimezonePicker = () => {
  const dispatch = useDispatch();
  const timezone = useSelector(getTimezone);

  const onTimezoneChange = useCallback(
    (tz: string) => {
      localStorage.setItem(TimezoneCookie, tz);
      dispatch(appActions.setTimezone(tz));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!timezone) {
      const cookieTimezone = localStorage.getItem(TimezoneCookie);
      if (cookieTimezone) {
        onTimezoneChange(cookieTimezone);
      }
    }
  }, [onTimezoneChange, timezone]);

  return (
    <div className='time-zone'>
      <Select
        showSearch
        className='time-zone-select'
        placeholder='Select Timezone'
        value={timezone}
        onChange={onTimezoneChange}
        filterOption={filterOption}>
        {timezones.map((zone: any) => (
          <Option value={zone.value} key={zone.id}>
            {zone.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default TimezonePicker;
