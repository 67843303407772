import { createSlice } from '@reduxjs/toolkit';
import { tableInitialState } from '../../../model/interfaces/shared/tableState';
import { UCDeviceState } from '../../../model/interfaces/ucDevice';
import { reducers } from './reducers';
import { extraReducers } from './extraReducers';

const initialState: UCDeviceState = {
  ...tableInitialState,
};

const ucDeviceSlice = createSlice({
  name: 'ucDevice',
  initialState,
  reducers,
  extraReducers,
});

export default ucDeviceSlice;

export const ucDeviceActions = ucDeviceSlice.actions;
