import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './extraReducers';
import { Status } from '../../../model/enum/Status';
import { Code, DeviceSetting } from '../../../model/interfaces/app';
import { User } from '../../../model/interfaces/user';
import { DeviceType } from '../../../model/interfaces/deviceType';

export interface CodesState {
  siDevices: Array<Code>;
  ucDevices: Array<Code>;
  labBundles: Array<Code>;
  instructors: Array<User>;
  students: Array<User>;
  deviceSettings: Record<string, Array<DeviceSetting>>;
  deviceTypes: Array<DeviceType>;
  status: Status;
}

const initialState: CodesState = {
  siDevices: [],
  ucDevices: [],
  labBundles: [],
  instructors: [],
  students: [],
  deviceSettings: {},
  deviceTypes: [],
  status: Status.INITIAL,
};

const codesSlice = createSlice({
  name: 'codes',
  initialState,
  reducers: {},
  extraReducers,
});

export default codesSlice;

export const codesActions = codesSlice.actions;
