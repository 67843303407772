import { AppState } from '../../../model/interfaces/app';

export const reducers = {
  setIsLoading(state: AppState, action: any) {
    state.isLoading = action.payload;
  },
  setToken(state: AppState, action: any) {
    state.token = action.payload;
  },
  setUser(state: AppState, action: any) {
    state.user = action.payload;
  },
  setTimezone(state: AppState, action: any) {
    state.timezone = action.payload;
  },
  setTimeFormat(state: AppState, action: any) {
    state.timeFormat = action.payload;
  },
  setDateFormat(state: AppState, action: any) {
    state.dateFormat = action.payload;
  },
  setRdpConnection(state: AppState, action: any) {
    state.rdpConnection = action.payload;
  },
  setDeviceStatus(state: AppState, action: any) {
    state.deviceStatus = {
      ...state.deviceStatus,
      [action.payload.name]: action.payload,
    };
  },
};
