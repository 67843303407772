import { BasePageRequest } from '../model/interfaces/shared/basePageRequest';
import { apiPost } from './Axios';
import { ScheduleFilterRequest, ScheduleLabFilterRequest } from '../model/interfaces/schedule';

export const filterScheduledLabsApi = (request: BasePageRequest<ScheduleFilterRequest>) => {
  return apiPost(`schedule/scheduled/filter`, request);
};

export const filterStudentLabsApi = (request: BasePageRequest<ScheduleLabFilterRequest>) => {
  return apiPost(`schedule/scheduled/filter-student`, request);
};
