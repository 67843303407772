import { StudentState } from '../../../model/interfaces/student';

export const reducers = {
  setCurrentPage(state: StudentState, action: any) {
    state.page.currentPage = action.payload.current > 0 ? action.payload.current - 1 : 0;
  },
  setRequest(state: StudentState, action: any) {
    state.request = action.payload;
    state.page.currentPage = 0;
  },
  setDate(state: StudentState, action: any) {
    state.date = action.payload;
  },
};
