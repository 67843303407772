import React, { useEffect } from 'react';
import { Button, Card, Col, Form, Input, Row, Space, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import InputRemove from '../../components/shared/InputRemove';
import useAxios from '../../hooks/useAxios';
import HttpMethod from '../../model/enum/HttpMethod';
import useNotification from '../../hooks/useNotification';

const NewLabTopology = () => {
  const [form] = Form.useForm();
  const { id }: any = useParams();
  const { successNotification } = useNotification();
  const { result, loading, run } = useAxios();
  const { result: details, loading: loadingDetails, run: getDetails } = useAxios();
  const isEdit = !!id;

  useEffect(() => {
    if (isEdit) {
      getDetails({
        url: `lab-topology/${id}`,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (details) {
      form.setFieldsValue(details);
    }
  }, [details]);

  useEffect(() => {
    if (result) {
      if (isEdit) {
        successNotification('Lab topology updated');
      } else {
        successNotification('Lab topology created');
      }
      history.back();
    }
  }, [result]);

  const onFinish = (values: any) => {
    if (isEdit) {
      run({
        url: 'lab-topology/update',
        method: HttpMethod.PUT,
        body: {
          id: isEdit ? id : null,
          ...details,
          ...values,
        },
      });
    } else {
      run({
        url: 'lab-topology/create',
        method: HttpMethod.POST,
        body: values,
      });
    }
  };

  return (
    <Spin spinning={loadingDetails || loading}>
      <Card>
        <Form
          form={form}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          labelAlign='left'
          onFinish={onFinish}
          initialValues={{ logicalDevices: [''] }}>
          <Row gutter={64}>
            <Col span={24} md={{ span: 16, offset: 4 }}>
              <Form.Item
                label='Name'
                name='name'
                colon={false}
                rules={[{ required: true, message: 'Please enter lab topology name!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label='Description'
                name='description'
                colon={false}
                rules={[{ required: true, message: 'Please enter lab topology description!' }]}>
                <Input />
              </Form.Item>
              <Form.Item label='Lab Topology Devices' colon={false}>
                <Form.List name='logicalDevices'>
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field) => (
                        <Form.Item key={field.key}>
                          <Form.Item
                            {...field}
                            className='m-b-8'
                            noStyle
                            name={[field.name, 'logicalName']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Please enter device name!',
                              },
                            ]}>
                            <InputRemove
                              placeholder='Device name'
                              remove={remove}
                              showRemove={fields.length > 1}
                              name={field.name}
                            />
                          </Form.Item>
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                          Add field
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Col>
          </Row>
          <Space className='flex-center margin-top-24'>
            <Button onClick={() => history.back()}>Cancel</Button>
            {!isEdit && (
              <Button type='primary' htmlType='submit'>
                Create
              </Button>
            )}
            {isEdit && (
              <Button type='primary' htmlType='submit'>
                Save
              </Button>
            )}
          </Space>
        </Form>
      </Card>
    </Spin>
  );
};

export default NewLabTopology;
