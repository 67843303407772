import { notification } from 'antd';

const useNotification = () => {
  const successNotification = (message: string, description?: string) => {
    notification.success({
      message,
      description,
      duration: 5,
    });
  };

  const errorNotification = (message: string, description?: string) => {
    notification.error({
      message,
      description,
      duration: 5,
    });
  };

  const infoNotification = (message: string, description?: string) => {
    notification.info({
      message,
      description,
      duration: 5,
    });
  };

  const warningNotification = (message: string, description?: string) => {
    notification.warning({
      message,
      description,
      duration: 5,
    });
  };

  return {
    successNotification,
    errorNotification,
    infoNotification,
    warningNotification,
  };
};

export default useNotification;
