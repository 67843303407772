import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import './Breadcrumbs.less';

import { Breadcrumb } from 'antd';

const Breadcrumbs = (props: RouteComponentProps) => {
  const routes: any = {
    '/home': 'Home',
    '/profile': 'Profile',
    '/new': 'New',
    '/edit': 'Edit',
    '/device-type': 'Device Type',
    '/device-type/new': 'New',
    '/device-type/edit': 'Edit',
    '/lab-topology': 'Lab topology',
    '/lab-topology/new': 'New',
    '/lab-topology/edit': 'Edit',
    '/lab': 'Labs',
    '/lab/new': 'New',
    '/lab/edit': 'Edit',
    '/uc-device': 'User Controlled Devices',
    '/uc-device/new': 'New',
    '/uc-device/edit': 'Edit',
    '/si-device': 'Support Infrastructure Devices',
    '/si-device/new': 'New',
    '/si-device/edit': 'Edit',
    '/exercise': 'Exercise',
    '/exercise/new': 'New',
    '/exercise/edit': 'Edit',
    '/equipment': 'Equipment',
    '/exercise/equipment': 'Equipment',
    '/exercise/equipment/edit': 'Edit',
    '/lab-bundle': 'Lab bundle',
    '/lab-bundle/new': 'New',
    '/lab-bundle/edit': 'Edit',
    '/availability': 'Availability',
    '/lab-details': 'Lab details',
    '/home/lab-details': 'Lab details',
    '/home/lab-details/lab': 'Lab',
    '/home/lab': 'Lab',
    '/tokens': 'Tokens',
    '/student-tokens': 'Tokens',
  };

  const disabled: any = {
    '/equipment': true,
  };

  const { location } = props;

  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((snippet, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    const exists = !!routes['/' + snippet];
    const isLast = !routes['/' + pathSnippets[index + 1]];
    const isPreLast = !routes['/' + pathSnippets[index + 2]];
    const isDisabled = disabled['/' + snippet];

    if (!exists) {
      return null;
    }

    if (isLast) {
      return (
        <Breadcrumb.Item key={url}>
          <span className='last-breadcrumb'>{routes[url]}</span>
        </Breadcrumb.Item>
      );
    }

    if (isDisabled) {
      return (
        <Breadcrumb.Item key={url}>
          <span>{routes[url]}</span>
        </Breadcrumb.Item>
      );
    } else if (isPreLast) {
      return (
        <Breadcrumb.Item key={url} onClick={() => history.back()}>
          <span style={{ cursor: 'pointer' }}>{routes[url]}</span>
        </Breadcrumb.Item>
      );
    } else {
      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{routes[url]}</Link>
        </Breadcrumb.Item>
      );
    }
  });

  return <Breadcrumb>{extraBreadcrumbItems}</Breadcrumb>;
};

export default withRouter(Breadcrumbs);
