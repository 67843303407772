import React from 'react';
import useHelper from '../../hooks/useHelper';
import { Roles } from '../../model/enum/Roles';
import LabAdminHomepage from './LabAdmin/LabAdminHomepage';
import SchedulingHomepage from './Scheduling/SchedulingHomepage';
import InstructorHomepage from './Instructor/InstructorHomepage';
import StudentHomepage from './Student/StudentHomepage';

const Homepage = () => {
  const { isAuthorized } = useHelper();

  return (
    <>
      {isAuthorized([Roles.STUDENT]) && <StudentHomepage />}
      {isAuthorized([Roles.INSTRUCTOR]) && <InstructorHomepage />}
      {isAuthorized([Roles.LAB_ADMIN]) && <LabAdminHomepage />}
      {isAuthorized([Roles.SCHEDULING_ADMIN]) && <SchedulingHomepage />}
    </>
  );
};

export default Homepage;
