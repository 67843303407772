import axios from 'axios';
import useNotification from '../hooks/useNotification';
import keycloak from '../keycloak';
import config from '../util/config';

const nilAxios = (url: string, method: string, body?: any, headers?: any) => {
  const { errorNotification } = useNotification();

  const axiosInstance = axios.create({
    baseURL: config.baseUrl,
    headers: { Authorization: `Bearer ${keycloak.token}`, ...headers }, // token is automatically refreshed
  });

  const handleErrors = (error: any): void => {
    switch (error.response.status) {
      case 400:
        if (error.response && error.response.data) {
          errorNotification(error.response.data);
        } else {
          errorNotification('Bad request. Check what values are send to backend');
        }
        break;
      case 404:
        errorNotification('Not found');
        break;
      case 500:
        if (error.response && error.response.data) {
          if (typeof error.response.data === 'object') {
            errorNotification(
              Object.keys(error.response.data)
                .map((key: string) => `${key}: ${error.response.data[key]}`)
                .join(',')
            );
          } else {
            errorNotification(error.response.data);
          }
        } else {
          errorNotification('Something went wrong, please try again later');
        }
        break;
      case 503:
        errorNotification('Server is unavailable');
        break;
      default:
        errorNotification('Unknown error occurred');
        break;
    }
  };

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.status === 201 || (response.status === 200 && !response.data)) {
        return { data: true };
      }
      return response;
    },
    (error) => {
      handleErrors(error);
      return Promise.reject(error);
    }
  );

  return (axiosInstance as any)[method.toLowerCase()](url, body);
};

export default nilAxios;

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const apiGet = (route: string) => nilAxios(route, HttpMethod.GET);
export const apiPost = (route: string, body?: any) => nilAxios(route, HttpMethod.POST, body);
export const apiPut = (route: string, body?: any) => nilAxios(route, HttpMethod.PUT, body);
export const apiDelete = (route: string) => nilAxios(route, HttpMethod.DELETE);
