import { createAsyncThunk } from '@reduxjs/toolkit';
import { BasePageRequest } from '../../../model/interfaces/shared/basePageRequest';
import { SIDeviceFilterRequest } from '../../../model/interfaces/siDevice';
import { filterSIDeviceApi } from '../../../service/SIDeviceService';
import { SIDeviceState } from '../../../model/interfaces/siDevice';
import { Status } from '../../../model/enum/Status';

export const filterSIDevice = createAsyncThunk(
  'getSIDevice',
  async (command: BasePageRequest<SIDeviceFilterRequest>) => {
    const response = await filterSIDeviceApi(command);
    return response.data;
  }
);

export const extraReducers = (builder: any) => {
  builder.addCase(filterSIDevice.pending, (state: SIDeviceState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(filterSIDevice.fulfilled, (state: SIDeviceState, action: any) => {
    state.status = Status.SUCCESS;
    state.page = action.payload.page;
    state.data = action.payload.response;
  });
  builder.addCase(filterSIDevice.rejected, (state: SIDeviceState) => {
    state.status = Status.ERROR;
    state.data = [];
  });
};
