import React, { useEffect } from 'react';
import { Button, Input, Popconfirm, Table } from 'antd';
import { Link } from 'react-router-dom';
import { Status } from '../../model/enum/Status';
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentPage,
  getData,
  getPage,
  getRequest,
  getStatus,
} from '../../store/slices/lab/selectors';
import { itemRender } from '../../components/shared/TableItemRender';
import { TableActions } from '../../model/enum/TableActions';
import { labActions } from '../../store/slices/lab/state';
import { filterLab } from '../../store/slices/lab/extraReducers';
import useAxios from '../../hooks/useAxios';
import HttpMethod from '../../model/enum/HttpMethod';
import { checkCurrentPage, getDefaultSort } from '../../util/helperUtils';
import useNotification from '../../hooks/useNotification';

const { Search } = Input;

const Lab = () => {
  const dispatch = useDispatch();
  const { successNotification } = useNotification();
  const data = useSelector(getData);
  const page = useSelector(getPage);
  const status = useSelector(getStatus);
  const request = useSelector(getRequest);
  const currentPage = useSelector(getCurrentPage);

  const { result, loading, run, param } = useAxios();

  useEffect(() => {
    if (result) {
      fetchData(true);
    }
  }, [result]);

  useEffect(() => {
    fetchData();
  }, [request, currentPage, page.sortProperty, page.sortDirection]);

  const fetchData = (itemDeleted?: boolean) => {
    if (itemDeleted) {
      successNotification('Lab deleted');
    }
    dispatch(
      filterLab({
        request: {
          ...request,
        },
        ...page,
        currentPage: checkCurrentPage(page, itemDeleted),
      })
    );
  };

  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: getDefaultSort(page, 'name'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: true,
      defaultSortOrder: getDefaultSort(page, 'description'),
    },
    {
      title: 'Workgroup',
      dataIndex: 'workgroupDescription',
      key: 'workgroupDescription',
      sorter: true,
      defaultSortOrder: getDefaultSort(page, 'workgroupDescription'),
    },
    {
      title: 'Topology',
      dataIndex: 'labTopology.name',
      key: 'labTopology',
      sorter: true,
      render: (text: any, record: any) => record.labTopology.description,
    },
    {
      title: '',
      key: 'action',
      render: (record: any) => (
        <div onClick={(e) => e.stopPropagation()} className={'flex-center'}>
          <Link to={`/lab/edit/${record.id}`}>
            <Button type='text' color='primary' shape='circle' icon={<EditOutlined />} />
          </Link>
          <Popconfirm
            placement='left'
            title='Are you sure you want to delete this lab?'
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() =>
              run({
                url: `lab/delete/`,
                method: HttpMethod.DELETE,
                param: record.id,
              })
            }
            okText='Yes'
            cancelText='No'>
            <Button
              danger
              type='text'
              shape='circle'
              icon={param === record.id && loading ? <LoadingOutlined /> : <DeleteOutlined />}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    switch (extra.action) {
      case TableActions.FILTER:
        dispatch(labActions.setRequest(filters));
        break;
      case TableActions.SORT:
        dispatch(labActions.setPage({ page, sorter }));
        break;
      case TableActions.PAGINATE:
        dispatch(labActions.setCurrentPage(pagination));
        break;
    }
  };

  const onSearch = (search: string) => {
    dispatch(
      labActions.setRequest({
        ...request,
        search,
      })
    );
  };

  return (
    <>
      <div className='flex-between m-b-16'>
        <Search
          defaultValue={request?.search}
          placeholder='Search'
          className='max-w-300'
          allowClear
          enterButton
          onSearch={onSearch}
        />

        <Button type='primary' className='m-l-8'>
          <Link to='/lab/new'>Create new</Link>
        </Button>
      </div>
      <Table
        rowKey='id'
        columns={columns}
        dataSource={data}
        pagination={{
          position: ['bottomCenter'],
          size: 'small',
          itemRender,
          current: currentPage + 1,
          pageSize: page?.size,
          total: page?.totalElements,
          showSizeChanger: false,
        }}
        onChange={onChange}
        loading={status === Status.LOADING}
        scroll={{ x: 300, scrollToFirstRowOnChange: true }}
      />
    </>
  );
};

export default Lab;
