import { RootState } from '../../index';

export const getStatus = (state: RootState) => {
  return state.labTopology.status;
};

export const getCurrentPage = (state: RootState) => {
  return state.labTopology.page.currentPage;
};

export const getPage = (state: RootState) => {
  return state.labTopology.page;
};

export const getRequest = (state: RootState) => {
  return state.labTopology.request;
};

export const getData = (state: RootState) => {
  return state.labTopology.data;
};
