import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '../../../model/interfaces/app';
import { getUserApi, updateUserApi } from '../../../service/UserService';
import { Status } from '../../../model/enum/Status';
import { UserSaveRequest } from '../../../model/interfaces/user';

export const getUser = createAsyncThunk('getApp', async (userId: string) => {
  const response = await getUserApi(userId);
  return response.data;
});

export const updateUser = createAsyncThunk('updateUser', async (request: UserSaveRequest) => {
  const response = await updateUserApi(request);
  return response.data;
});

export const extraReducers = (builder: any) => {
  builder.addCase(getUser.pending, (state: AppState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(getUser.fulfilled, (state: AppState, action: any) => {
    state.status = Status.SUCCESS;
    state.user = {
      ...state.user,
      ...action.payload,
    };
  });
  builder.addCase(getUser.rejected, (state: AppState) => {
    state.status = Status.ERROR;
  });

  builder.addCase(updateUser.pending, (state: AppState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(updateUser.fulfilled, (state: AppState, action: any) => {
    state.status = Status.SUCCESS;
    state.user = {
      ...state.user,
      ...action.payload,
    };
  });
  builder.addCase(updateUser.rejected, (state: AppState) => {
    state.status = Status.ERROR;
  });
};
