import React from 'react';
import { Button, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const InputRemove = ({
  value = null,
  onChange = (_: string) => {},
  remove = (_: any) => {},
  showRemove = false,
  ...props
}) => {
  const { name } = props;

  const triggerChange = (changedValue: any) => {
    onChange?.(changedValue);
  };

  return (
    <Input.Group compact className={'flex'}>
      <Input {...props} value={value} onChange={triggerChange} />
      {showRemove && <Button onClick={() => remove(name)} icon={<DeleteOutlined />} />}
    </Input.Group>
  );
};

export default InputRemove;
