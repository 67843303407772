import React from 'react';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
import { Avatar, Dropdown, Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';
import './Header.less';
import logo from '../../../assets/images/nil-logo.png';
import { getFullName, getUsername } from '../../../store/slices/app/selectors';

const Header = () => {
  const { keycloak } = useKeycloak();
  const fullName = useSelector(getFullName);
  const username = useSelector(getUsername);

  const menu = (
    <Menu
      items={[
        {
          key: 'logoutButton',
          label: 'Logout',
          onClick: () => keycloak.logout(),
        },
      ]}
    />
  );

  return (
    <Layout.Header className='flex-between'>
      <div className='nil-logo-container'>
        <img src={logo} alt='logo' className='nil-logo' />
      </div>
      <div className='flex-align-center'>
        <Dropdown overlay={menu} trigger={['click']} className='profile-dropdown'>
          <span className='ant-dropdown-link pointer'>
            <Avatar size='small' icon={<UserOutlined />} className='avatar-icon' />
            {fullName || username}
            <DownOutlined className='chevron-icon' />
          </span>
        </Dropdown>
      </div>
    </Layout.Header>
  );
};

export default Header;
