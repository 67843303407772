import { Page, pageInitialState } from './page';
import { Status } from '../../enum/Status';

export interface TableState<T, K> {
  data: T[];
  page: Page;
  status: Status;
  request: K;
}

export const tableInitialState: TableState<any, any> = {
  data: [],
  page: pageInitialState,
  status: Status.INITIAL,
  request: {},
};
