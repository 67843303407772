import { createSlice } from '@reduxjs/toolkit';
import { tableInitialState } from '../../../model/interfaces/shared/tableState';
import { LabState } from '../../../model/interfaces/lab';
import { reducers } from './reducers';
import { extraReducers } from './extraReducers';

const initialState: LabState = {
  ...tableInitialState,
};

const labSlice = createSlice({
  name: 'lab',
  initialState,
  reducers,
  extraReducers,
});

export default labSlice;

export const labActions = labSlice.actions;
