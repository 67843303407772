import React, { useEffect } from 'react';
import { Card, Empty, List, Space, Spin } from 'antd';
import Search from 'antd/es/input/Search';
import { useDispatch, useSelector } from 'react-redux';
import { filterScheduledLabs } from '../../../store/slices/schedule/extraReducers';
import {
  getCurrentPage,
  getData,
  getDate,
  getPage,
  getRequest,
  getStatus,
} from '../../../store/slices/schedule/selectors';
import { scheduleActions } from '../../../store/slices/schedule/state';
import { itemRender } from '../../../components/shared/TableItemRender';
import DatePicker from '../../../components/shared/DatePicker/DatePicker';
import AdminLabCard from '../../../components/AdminLabCard';
import { Status } from '../../../model/enum/Status';

const LabAdminHomepage = () => {
  const dispatch = useDispatch();
  const date = useSelector(getDate);
  const data = useSelector(getData);
  const page = useSelector(getPage);
  const status = useSelector(getStatus);
  const request = useSelector(getRequest);
  const currentPage = useSelector(getCurrentPage);

  useEffect(() => {
    fetchData();
  }, [request, currentPage, page.sortProperty, page.sortDirection, date]);

  const fetchData = () => {
    dispatch(
      filterScheduledLabs({
        request: {
          ...request,
          date,
        },
        ...page,
      })
    );
  };

  const onChange = (page: number) => {
    dispatch(scheduleActions.setCurrentPage({ current: page }));
  };

  const onSearch = (search: string) => {
    dispatch(
      scheduleActions.setRequest({
        ...request,
        search,
      })
    );
  };

  return (
    <>
      <Space className='flex-start m-b-16'>
        <Search
          defaultValue={request?.search}
          placeholder='Search'
          className='max-w-300'
          allowClear
          enterButton
          onSearch={onSearch}
        />

        <DatePicker
          value={date}
          placeholder='Date'
          allowClear={false}
          onChange={(value: any) => dispatch(scheduleActions.setDate(value))}
        />
      </Space>
      <Spin spinning={status === Status.LOADING}>
        <>
          {data.length === 0 && (
            <Card>
              <Empty />
            </Card>
          )}
          {data.length > 0 && (
            <List
              pagination={{
                onChange,
                size: 'small',
                itemRender,
                current: currentPage + 1,
                pageSize: page?.size,
                total: page?.totalElements,
                showSizeChanger: false,
              }}
              grid={{
                xs: 1,
                sm: 1,
                md: 2,
                lg: 2,
                xl: 2,
                xxl: 3,
                gutter: 16,
              }}
              dataSource={data}
              renderItem={(schedule) => {
                return (
                  <List.Item key={schedule.id}>
                    <AdminLabCard schedule={schedule} />
                  </List.Item>
                );
              }}
            />
          )}
        </>
      </Spin>
    </>
  );
};

export default LabAdminHomepage;
