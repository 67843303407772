import React, { useMemo } from 'react';
import { Layout, Menu } from 'antd';
import {
  CalendarOutlined,
  ClusterOutlined,
  CodeOutlined,
  ControlOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  SlidersOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import useHelper from '../../../hooks/useHelper';
import { Roles } from '../../../model/enum/Roles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUsername } from '../../../store/slices/app/selectors';

const { Sider } = Layout;

const SideMenu = () => {
  const history = useHistory();
  const username = useSelector(getUsername);
  const { isAuthorized } = useHelper();
  const { pathname } = history.location;
  const root = '/' + history.location.pathname.split('/')[1];

  const menuItems = [
    {
      key: '/home',
      icon: <DesktopOutlined />,
      label: 'Home',
    },
    {
      key: '/availability',
      icon: <CalendarOutlined />,
      label: 'Availability',
      roles: [Roles.SCHEDULING_ADMIN],
    },
    {
      key: '/tokens',
      icon: <TeamOutlined />,
      label: 'Tokens',
      roles: [Roles.SCHEDULING_ADMIN],
    },
    {
      key: '/student-tokens',
      icon: <TeamOutlined />,
      label: 'Tokens',
      roles: [Roles.STUDENT],
    },
    {
      key: '/profile',
      icon: <UserOutlined />,
      label: 'Profile',
    },
    {
      key: '/device-type',
      icon: <SlidersOutlined />,
      label: 'Device Type',
      roles: [Roles.LAB_ADMIN],
    },
    {
      key: '/uc-device',
      icon: <ControlOutlined />,
      label: 'UC Devices',
      roles: [Roles.LAB_ADMIN],
    },
    {
      key: '/si-device',
      icon: <DatabaseOutlined />,
      label: 'SI Devices',
      roles: [Roles.LAB_ADMIN],
    },
    {
      key: '/lab-topology',
      icon: <ClusterOutlined />,
      label: 'Lab Topology',
      roles: [Roles.LAB_ADMIN],
    },
    {
      key: '/lab',
      icon: <DesktopOutlined />,
      label: 'Labs',
      roles: [Roles.LAB_ADMIN],
    },
    {
      key: '/lab-bundle',
      icon: <UnorderedListOutlined />,
      label: 'Lab Bundle',
      roles: [Roles.LAB_ADMIN],
    },
    {
      key: '/exercise',
      icon: <CodeOutlined />,
      label: 'Exercise',
      roles: [Roles.LAB_ADMIN],
    },
  ];

  const onMenuClick = (e: any) => {
    history.push(e.key);
  };

  const authorizedMenuItems = useMemo((): any[] => {
    return menuItems.filter((i: any) => !i.roles || isAuthorized(i.roles));
  }, [username]);

  return (
    <Sider collapsible breakpoint='lg' collapsedWidth='60' width={250}>
      <Menu
        mode='inline'
        selectedKeys={[root, pathname]}
        items={authorizedMenuItems}
        onClick={onMenuClick}
      />
    </Sider>
  );
};

export default SideMenu;
