import { Button, Col, Form, Input, Modal, Row, Select, Space, Spin } from 'antd';
import useAxios from 'hooks/useAxios';
import useNotification from 'hooks/useNotification';
import HttpMethod from 'model/enum/HttpMethod';
import { Exercise } from 'model/interfaces/exercise';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { studentsCodes } from 'store/slices/codes/selectors';
import { filterOption } from 'util/helperUtils';

interface TokenExercise {
  id: string;
  name: string;
  durationMinutes: number;
}
interface StartTokenSchedule {
  closeModal: (refresh?: boolean) => void;
  id?: string;
  exercises?: TokenExercise[];
}
const StartTokenScheduleModal = ({ closeModal, id, exercises }: StartTokenSchedule) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [exercise, setExercise] = useState(null);
  const students = useSelector(studentsCodes);
  const { successNotification } = useNotification();
  const { result, loading, run } = useAxios();

  const selectExercise = (id: any) => {
    setExercise(exercises.find((e: TokenExercise) => e.id === id));
  };

  const { Option } = Select;

  const exerciseOptions = exercises?.map((e: TokenExercise) => {
    return (
      <Option key={e.id} value={e.id}>
        {e.name}
      </Option>
    );
  });

  const onCancel = () => {
    closeModal();
  };

  useEffect(() => {
    if (loading == false && result) {
      successNotification('Lab schedule created');
      closeModal(true);
    }
  }, [loading]);

  const onFinish = (values: any) => {
    run({
      url: 'token/start',
      method: HttpMethod.POST,
      body: {
        exerciseId: values.exerciseId,
        id: id,
      },
    });
  };

  return (
    <Modal
      title='Start new lab'
      visible={true}
      footer={null}
      centered
      closable={false}
      maskClosable={false}
      destroyOnClose
      width='1000px'>
      <Form
        form={form}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        labelAlign='left'
        onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              label='Exercise'
              name='exerciseId'
              colon={false}
              rules={[{ required: true, message: 'Please select exercise!' }]}>
              <Select
                showSearch
                filterOption={filterOption}
                placeholder='Select Exercise'
                onSelect={selectExercise}>
                {exerciseOptions}
              </Select>
            </Form.Item>
            {/* <Form.Item label='Custom name' name='customName' colon={false}>
                <Input />
              </Form.Item> */}
          </Col>
        </Row>

        <Space className='flex-center margin-top-24'>
          <Button onClick={onCancel}>Cancel</Button>
          <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
            {() => (
              <Button
                type='primary'
                htmlType='submit'
                loading={loading}
                disabled={
                  !form.isFieldsTouched(['exerciseId'], true) ||
                  form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                  loading
                }>
                Create
              </Button>
            )}
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default StartTokenScheduleModal;
