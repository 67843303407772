import { Button, Table } from 'antd';
import { Exercise } from '../../model/interfaces/exercise';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import React from 'react';
import useTimezone from '../../hooks/useTimezone';

const ExerciseEquipmentTable = ({ record }: { record: Exercise }) => {
  const { formatUtcDate } = useTimezone();

  const columns = [
    {
      title: 'Exercise device name',
      dataIndex: 'exerciseDeviceName',
      key: 'exerciseDeviceName',
    },
    {
      title: 'Created',
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (date: Date) => formatUtcDate(date),
    },
    {
      title: 'Modified',
      dataIndex: 'lastModified',
      key: 'lastModified',
      render: (date: Date) => formatUtcDate(date),
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
    },
    {
      title: '',
      key: 'action',
      render: (record: any) => (
        <div onClick={(e) => e.stopPropagation()} className={'flex-center'}>
          <Link to={`/exercise/equipment/edit/${record.id}`}>
            <Button type='text' color='primary' shape='circle' icon={<EditOutlined />} />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className='expandable-device-config'>
      <div className='expandable-container'>
        <Table
          rowKey='id'
          columns={columns}
          dataSource={record.exerciseEquipments}
          size='small'
          pagination={false}
        />
      </div>
    </div>
  );
};

export default ExerciseEquipmentTable;
