import { RootState } from '../../index';

export const getStatus = (state: RootState) => {
  return state.lab.status;
};

export const getCurrentPage = (state: RootState) => {
  return state.lab.page.currentPage;
};

export const getPage = (state: RootState) => {
  return state.lab.page;
};

export const getRequest = (state: RootState) => {
  return state.lab.request;
};

export const getData = (state: RootState) => {
  return state.lab.data;
};
