import { SortDirection } from '../model/enum/SortDirection';
import { Page } from '../model/interfaces/shared/page';
import { DeviceStatus } from '../model/enum/DeviceStatus';

export const checkSortOrder = (order: string) => {
  if (order === 'descend' || order === undefined) {
    return SortDirection.DESC;
  } else if (order === 'ascend') {
    return SortDirection.ASC;
  }
};

export const checkSortProperty = (property: string) => {
  if (property === 'startDate') {
    return 'startTime';
  } else if (property === 'finishDate') {
    return 'scanResult.finishedAt';
  } else {
    return property;
  }
};

export const checkCurrentPage = (page: Page, itemDeleted?: boolean) => {
  const { totalPages, currentPage, size, totalElements } = page;
  let newPage = currentPage ? currentPage : 0;

  if (itemDeleted && totalPages > 1 && currentPage != 0) {
    if (totalElements % size === 1) {
      newPage = newPage - 1;
    }
  }

  return newPage;
};

export const getDefaultSort = (page: Page, property: string): string => {
  const { sortDirection, sortProperty } = page;

  if (sortProperty === property) {
    return sortDirection === SortDirection.DESC ? 'descend' : 'ascend';
  }

  return null;
};

export const filterOption = (input: string, option: any) =>
  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export const getProgressStatus: any = (status: DeviceStatus): string => {
  if (status === DeviceStatus.LOADING) {
    return 'active';
  }
  if (status === DeviceStatus.ERROR) {
    return 'exception';
  }
  if (status === DeviceStatus.READY) {
    return 'success';
  }
  return 'normal';
};

const colorList: Array<string> = [
  // Light NIL colors
  '#fde45f',
  '#00b695',
  '#58b6e7',
  '#b22842',
  '#2e3c83',
  // // Medium NIL colors
  '#fec059',
  '#009283',
  '#3a7a99',
  '#891f3b',
  '#2a2758',
  // // Dark NIL colors
  '#ce7b2a',
  '#175d5c',
  '#30637f',
  '#6f141e',
  '#1b1d3b',
];
let colorMap: any = {};

export const getLabBundleColor = (labBundleId: string) => {
  let color = colorMap[labBundleId];
  if (color) {
    return color;
  } else {
    const size = Object.keys(colorMap).length;
    let index = size;
    if (size > colorList.length) {
      index = size % colorList.length;
    }
    colorMap[labBundleId] = colorList[index];
    return colorMap[labBundleId];
  }
};

export const getTextColor = (color: string) => {
  switch (color) {
    case '#fde45f':
    case '#fec059':
    case '#ce7b2a':
      return '#000000';
    default:
      return '#ffffff';
  }
};
