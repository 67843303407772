import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { filterTokenLabs } from 'store/slices/token/extraReducers';
import { itemRender } from '../../../components/shared/TableItemRender';
import { TableActions } from 'model/enum/TableActions';
import { tokenActions } from 'store/slices/token/state';
import { Status } from 'model/enum/Status';
import {
  getCurrentPage,
  getData,
  getPage,
  getRequest,
  getStatus,
} from 'store/slices/token/selectors';
import useAxios from 'hooks/useAxios';
import useNotification from 'hooks/useNotification';
import { checkCurrentPage, getDefaultSort } from 'util/helperUtils';
import keycloak from 'keycloak';
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  QuestionCircleOutlined,
  StopOutlined,
} from '@ant-design/icons';
import HttpMethod from 'model/enum/HttpMethod';
import StartTokenScheduleModal from 'components/modal/StartTokenScheduleModal';
import { getUsername } from 'store/slices/app/selectors';
import { Schedule } from 'model/interfaces/schedule';
import { NowTimer } from '@fullcalendar/react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import useTimezone from 'hooks/useTimezone';

const StudentTokens = () => {
  const dispatch = useDispatch();
  const { successNotification } = useNotification();
  const data = useSelector(getData);
  const page = useSelector(getPage);
  const status = useSelector(getStatus);
  const request = useSelector(getRequest);
  const username = useSelector(getUsername);
  const currentPage = useSelector(getCurrentPage);
  const { result, loading, run, param } = useAxios();
  const [showModal, setShowModal] = useState(false);
  const [startId, setStartId] = useState();
  const [labBundleId, setLabBundleId] = useState();
  const [exercises, setExercises] = useState(null);
  const { formatUtcDate, datePickerFormat, timePickerFormat, addTimezoneOnDate, newDate } =
    useTimezone();
  const history = useHistory();

  useEffect(() => {
    fetchData();
  }, [request, currentPage, page.sortProperty, page.sortDirection, result, username]);

  function isLabRunning(schedule: any) {
    if (schedule == null) {
      return false;
    }
    if (addTimezoneOnDate(schedule.endDate).toDate().getTime() < new Date().getTime()) {
      return false;
    }
    return true;
  }

  const fetchData = (itemDeleted?: boolean) => {
    if (itemDeleted) {
      successNotification('Token deleted');
    }
    username &&
      dispatch(
        filterTokenLabs({
          request: {
            ...request,
            username: username,
          },
          ...page,
          currentPage: checkCurrentPage(page, itemDeleted),
        })
      );
  };

  const closeModal = (refresh: boolean) => {
    setShowModal(false);
    setExercises(null);
    setStartId(null);
    if (refresh) {
      fetchData();
    }
  };

  const openModal = (id: any, exercises: any) => {
    setStartId(id);
    setExercises(exercises);
    setShowModal(true);
  };

  const columns: any = [
    {
      title: 'Lab Bundle',
      dataIndex: 'labBundle',
      key: 'labBundle',
      sorter: true,
      defaultSortOrder: getDefaultSort(page, 'labBundle'),
    },
    {
      title: 'Available until',
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: false,
      render: (text: any, record: any) => formatUtcDate(record.endDate),
    },
    {
      title: 'Remaining Minutes',
      dataIndex: 'tokenAmountMinutes',
      key: 'tokenAmountMinutes',
      sorter: true,
      defaultSortOrder: getDefaultSort(page, 'tokenAmountMinutes'),
    },
    // {
    //   title: 'Topology',
    //   dataIndex: 'labTopology.name',
    //   key: 'labTopology',
    //   sorter: true,
    //   render: (text: any, record: any) => record.labTopology.description,
    // },
    {
      title: 'Action',
      key: 'action',
      render: (record: any) => (
        <div onClick={(e) => e.stopPropagation()} className={'flex-center'}>
          {isLabRunning(record.schedule) ? (
            <>
              <Button
                type='text'
                shape='circle'
                icon={<PlayCircleOutlined />}
                onClick={() => {
                  history.push('/');
                }}
              />
              <Popconfirm
                placement='left'
                title='Are you sure you want to end this Lab?'
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={() =>
                  run({
                    url: `token/end/`,
                    method: HttpMethod.PUT,
                    param: record.uniqueId,
                  })
                }
                okText='Yes'
                cancelText='No'>
                <Button
                  danger
                  type='text'
                  shape='circle'
                  icon={param === record.id && loading ? <LoadingOutlined /> : <StopOutlined />}
                />
              </Popconfirm>
            </>
          ) : (
            <Button
              type='text'
              shape='circle'
              icon={<PlayCircleOutlined />}
              onClick={() => openModal(record.id, record.exerciseList)}
            />
          )}
          <></>
        </div>
      ),
    },
  ];

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    switch (extra.action) {
      case TableActions.FILTER:
        dispatch(tokenActions.setRequest(filters));
        break;
      case TableActions.SORT:
        dispatch(tokenActions.setPage({ page, sorter }));
        break;
      case TableActions.PAGINATE:
        dispatch(tokenActions.setCurrentPage(pagination));
        break;
    }
  };

  return (
    <>
      {showModal && (
        <StartTokenScheduleModal id={startId} exercises={exercises} closeModal={closeModal} />
      )}
      <Table
        rowKey='id'
        columns={columns}
        dataSource={data}
        pagination={{
          position: ['bottomCenter'],
          size: 'small',
          itemRender,
          current: currentPage + 1,
          pageSize: page?.size,
          total: page?.totalElements,
          showSizeChanger: false,
        }}
        onChange={onChange}
        loading={status === Status.LOADING}
        scroll={{ x: 300, scrollToFirstRowOnChange: true }}
      />
    </>
  );
};

export default StudentTokens;
