import { createAsyncThunk } from '@reduxjs/toolkit';
import { BasePageRequest } from '../../../model/interfaces/shared/basePageRequest';
import { DeviceTypeFilterRequest, DeviceTypeState } from '../../../model/interfaces/deviceType';
import { filterDeviceTypeApi } from '../../../service/DeviceTypeService';
import { Status } from '../../../model/enum/Status';

export const filterDeviceType = createAsyncThunk(
  'getDeviceType',
  async (command: BasePageRequest<DeviceTypeFilterRequest>) => {
    const response = await filterDeviceTypeApi(command);
    return response.data;
  }
);

export const extraReducers = (builder: any) => {
  builder.addCase(filterDeviceType.pending, (state: DeviceTypeState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(filterDeviceType.fulfilled, (state: DeviceTypeState, action: any) => {
    state.status = Status.SUCCESS;
    state.page = action.payload.page;
    state.data = action.payload.response;
  });
  builder.addCase(filterDeviceType.rejected, (state: DeviceTypeState) => {
    state.status = Status.ERROR;
    state.data = [];
  });
};
