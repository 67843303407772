import { apiGet } from './Axios';

export const getSIDeviceCodesApi = () => {
  return apiGet(`code/si-device`);
};

export const getUCDeviceCodesApi = () => {
  return apiGet(`code/uc-device`);
};

export const getLabBundlesCodesApi = () => {
  return apiGet(`code/lab-bundle`);
};

export const getUserByRoleCodesApi = (role: string) => {
  return apiGet(`user/all/${role}`);
};

export const getDeviceSettingsApi = () => {
  return apiGet('code/device-settings');
};

export const getDeviceTypeCodesApi = () => {
  return apiGet('code/device-type');
};
