import { Modal, Select } from 'antd';
import { useState } from 'react';

const { Option } = Select;

interface ChangeDropdownProps {
  title: string;
  property: string;
  value?: string;
  data?: any[];
  loading?: boolean;
  confirmMessage?: string;
  onChange?: (value: any) => void;
}

const ChangeDropdown = ({
  value,
  title,
  data,
  property = 'id',
  loading = false,
  confirmMessage = 'Are you sure?',
  onChange,
}: ChangeDropdownProps) => {
  const [visible, setVisible] = useState(false);
  const [temp, setTemp] = useState();

  const confirmAction = () => {
    if (onChange) {
      onChange({ [property]: temp });
      cancelAction();
    }
  };

  const cancelAction = () => {
    setVisible(false);
    setTemp(null);
  };

  const showModal = (ex: any) => {
    setVisible(true);
    setTemp(ex);
  };

  return (
    <>
      <Modal
        width={400}
        okText='Yes'
        cancelText='No'
        closable={false}
        visible={visible}
        onOk={confirmAction}
        onCancel={cancelAction}
        bodyStyle={{ textAlign: 'center' }}>
        {confirmMessage}
      </Modal>
      <Select
        value={value || temp}
        onClick={(e) => e.preventDefault()}
        showSearch
        loading={loading}
        placeholder={title}
        onChange={(ex) => showModal(ex)}
        filterOption={(input: string, option: any) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }>
        {data?.map((item) => (
          <Option value={item.id} key={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default ChangeDropdown;
