import { createAsyncThunk } from '@reduxjs/toolkit';
import { Status } from '../../../model/enum/Status';
import {
  getDeviceSettingsApi,
  getDeviceTypeCodesApi,
  getLabBundlesCodesApi,
  getSIDeviceCodesApi,
  getUCDeviceCodesApi,
  getUserByRoleCodesApi,
} from '../../../service/CodeService';
import { CodesState } from './state';
import { Roles } from '../../../model/enum/Roles';

export const getSIDevicesCodes = createAsyncThunk('getSIDevicesCodes', async () => {
  const response = await getSIDeviceCodesApi();
  return response.data;
});

export const getUCDevicesCodes = createAsyncThunk('getUCDevicesCodes', async () => {
  const response = await getUCDeviceCodesApi();
  return response.data;
});

export const getLabBundlesCodes = createAsyncThunk('getLabBundlesCodes', async () => {
  const response = await getLabBundlesCodesApi();
  return response.data;
});

export const getInstructorsCodes = createAsyncThunk('getInstructorsCodes', async () => {
  const response = await getUserByRoleCodesApi(Roles.INSTRUCTOR);
  return response.data;
});

export const getStudentsCodes = createAsyncThunk('getStudentsCodes', async () => {
  const response = await getUserByRoleCodesApi(Roles.STUDENT);
  return response.data;
});

export const getDeviceSettings = createAsyncThunk('getDeviceSettings', async () => {
  const response = await getDeviceSettingsApi();
  return response.data;
});

export const getDeviceTypeCodes = createAsyncThunk('getDeviceTypeCodes', async () => {
  const response = await getDeviceTypeCodesApi();
  return response.data;
});

export const extraReducers = (builder: any) => {
  builder.addCase(getSIDevicesCodes.pending, (state: CodesState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(getSIDevicesCodes.fulfilled, (state: CodesState, action: any) => {
    state.status = Status.SUCCESS;
    state.siDevices = action.payload;
  });
  builder.addCase(getSIDevicesCodes.rejected, (state: CodesState) => {
    state.status = Status.ERROR;
    state.siDevices = [];
  });

  builder.addCase(getUCDevicesCodes.pending, (state: CodesState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(getUCDevicesCodes.fulfilled, (state: CodesState, action: any) => {
    state.status = Status.SUCCESS;
    state.ucDevices = action.payload;
  });
  builder.addCase(getUCDevicesCodes.rejected, (state: CodesState) => {
    state.status = Status.ERROR;
    state.ucDevices = [];
  });

  builder.addCase(getLabBundlesCodes.pending, (state: CodesState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(getLabBundlesCodes.fulfilled, (state: CodesState, action: any) => {
    state.status = Status.SUCCESS;
    state.labBundles = action.payload;
  });
  builder.addCase(getLabBundlesCodes.rejected, (state: CodesState) => {
    state.status = Status.ERROR;
    state.labBundles = [];
  });

  builder.addCase(getInstructorsCodes.pending, (state: CodesState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(getInstructorsCodes.fulfilled, (state: CodesState, action: any) => {
    state.status = Status.SUCCESS;
    state.instructors = action.payload;
  });
  builder.addCase(getInstructorsCodes.rejected, (state: CodesState) => {
    state.status = Status.ERROR;
    state.instructors = [];
  });

  builder.addCase(getStudentsCodes.pending, (state: CodesState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(getStudentsCodes.fulfilled, (state: CodesState, action: any) => {
    state.status = Status.SUCCESS;
    state.students = action.payload;
  });
  builder.addCase(getStudentsCodes.rejected, (state: CodesState) => {
    state.status = Status.ERROR;
    state.students = [];
  });

  builder.addCase(getDeviceSettings.pending, (state: CodesState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(getDeviceSettings.fulfilled, (state: CodesState, action: any) => {
    state.status = Status.SUCCESS;
    state.deviceSettings = action.payload;
  });
  builder.addCase(getDeviceSettings.rejected, (state: CodesState) => {
    state.status = Status.ERROR;
  });

  builder.addCase(getDeviceTypeCodes.pending, (state: CodesState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(getDeviceTypeCodes.fulfilled, (state: CodesState, action: any) => {
    state.status = Status.SUCCESS;
    state.deviceTypes = action.payload;
  });
  builder.addCase(getDeviceTypeCodes.rejected, (state: CodesState) => {
    state.status = Status.ERROR;
  });
};
