import { createSlice } from '@reduxjs/toolkit';
import { tableInitialState } from '../../../model/interfaces/shared/tableState';
import { SIDeviceState } from '../../../model/interfaces/siDevice';
import { reducers } from './reducers';
import { extraReducers } from './extraReducers';

const initialState: SIDeviceState = {
  ...tableInitialState,
};

const siDeviceSlice = createSlice({
  name: 'siDevice',
  initialState,
  reducers,
  extraReducers,
});

export default siDeviceSlice;

export const siDeviceActions = siDeviceSlice.actions;
