import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Switch,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getTimezone } from '../../store/slices/app/selectors';
import { filterOption } from '../../util/helperUtils';
import { Timezone } from '../../model/interfaces/shared/timezone';
import timezones from '../../util/timzones';
import { studentsCodes } from '../../store/slices/codes/selectors';
import { DeleteOutlined, QuestionCircleOutlined, StopOutlined } from '@ant-design/icons';
import useAxios from '../../hooks/useAxios';
import { getStudentsCodes } from '../../store/slices/codes/extraReducers';
import { LabBundle } from '../../model/interfaces/labBundle';
import { User } from '../../model/interfaces/user';
import HttpMethod from '../../model/enum/HttpMethod';
import useNotification from '../../hooks/useNotification';
import { TokenDate } from '../../model/interfaces/token';
import useTimezone from '../../hooks/useTimezone';
import DayForm from 'components/shared/DayForm';

const { Option } = Select;

interface TokenNewLab {
  closeModal: (refresh?: boolean) => void;
  startDate: string;
  endDate: string;
  id?: string;
}

const TokenNewLabModal = ({ closeModal, id, startDate, endDate }: TokenNewLab) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { removeTimezoneFromDate, addTimezoneOnDate, newDate, getDatesBetween, isBetween } =
    useTimezone();
  const timezone = useSelector(getTimezone);
  const [labBundle, setLabBundle] = useState(null);
  const students = useSelector(studentsCodes);
  const { successNotification } = useNotification();
  const { result, loading, run } = useAxios();
  const { result: deleteResult, loading: loadingDelete, run: runDelete } = useAxios();
  const { result: endResult, loading: loadingEnd, run: runEnd } = useAxios();
  const { result: details, loading: loadingDetails, run: getDetails } = useAxios();
  const { result: labBundles, loading: loadingLabBundles, run: getLabBundles } = useAxios();
  const isEdit = !!id;
  const [createStudent, setCreateStudent] = useState(false);
  const [scheduleId, setScheduleId] = useState();

  useEffect(() => {
    dispatch(getStudentsCodes());
    getLabBundles({
      url: 'lab-bundle/all',
    });
    if (startDate && endDate) {
      form.setFieldsValue({
        dates: [
          {
            startDate: newDate(startDate),
            endDate: newDate(endDate),
          },
        ],
      });
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      getDetails({
        url: `token/${id}`,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (details) {
      details.dates = [
        {
          startDate: newDate(details.startDate),
          endDate: newDate(details.endDate),
        },
      ];
      let labbundledesc = details.labbundle;
      details.labbundle = {
        id: details.labbundleid,
        code: 'C',
        description: labbundledesc,
      };
      const dts = prepareDatesForForm(details.dates);
      form.setFieldsValue({
        ...details,
        dates: dts,
        labBundleId: details.labBundleId,
      });
    }
  }, [details]);

  useEffect(() => {
    if (details && labBundles) {
      selectLabBundle(details.labBundleId);
    }
  }, [details, labBundles]);

  useEffect(() => {
    if (result) {
      if (isEdit) {
        successNotification('Token updated');
      } else {
        successNotification('Token created');
      }
      closeModal(true);
    }
  }, [result]);

  const onFinish = (values: any) => {
    if (isEdit) {
      run({
        url: 'token/update',
        method: HttpMethod.PUT,
        body: {
          ...details,
          ...values,
          id: isEdit ? id : null,
          dates: convertToUtcDates(values.dates, values.timezone),
        },
      });
    } else {
      run({
        url: 'token/create',
        method: HttpMethod.POST,
        body: {
          ...values,
          dates: convertToUtcDates(values.dates, values.timezone),
        },
      });
    }
  };

  const convertToUtcDates = (dates: Array<TokenDate>, timezone: string) => {
    return dates.map((d: TokenDate) => {
      return {
        startDate: removeTimezoneFromDate(d.startDate, timezone),
        endDate: removeTimezoneFromDate(d.endDate, timezone),
      };
    });
  };

  const onCancel = () => {
    closeModal();
  };

  const lbOptions = labBundles?.map((lb: LabBundle) => {
    return (
      <Option key={lb.id} value={lb.id}>
        {lb.description}
      </Option>
    );
  });

  const studentOptions = students.map((u: User) => {
    return (
      <Option key={u.id} value={u.id}>
        {`${u.username}`}
      </Option>
    );
  });

  const selectLabBundle = (id: any) => {
    setLabBundle(labBundles.find((lb: LabBundle) => lb.id === id));
  };

  const deleteToken = () => {
    runDelete({
      url: `token/delete/`,
      method: HttpMethod.DELETE,
      param: id,
    });
  };

  useEffect(() => {
    if (deleteResult) {
      successNotification('Token deleted');
      closeModal(true);
    }
  }, [deleteResult]);

  const endToken = () => {
    runEnd({
      url: `token/end/${details.uniqueId}`,
    });
  };

  useEffect(() => {
    if (endResult) {
      successNotification('Token ended');
      closeModal(true);
    }
  }, [endResult]);

  const prepareDatesForForm = (scheduleDates: Array<TokenDate>) => {
    let dates: any[] = [];
    if (scheduleDates) {
      const current = scheduleDates[0];
      if (current) {
        dates = [
          {
            startDate: newDate(addTimezoneOnDate(current.startDate).toISOString()).utc(),
            endDate: newDate(addTimezoneOnDate(current.endDate).toISOString()).utc(),
          },
        ];
      }
    }
    return dates;
  };

  const emailValidator = (rule: any, values: any, callback: any) => {
    if (!values) {
      callback();
      return;
    }

    const emailRegex = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
    const invalidInputs = values.filter((value: any) => !value.match(emailRegex));
    if (invalidInputs.length === 0) {
      callback();
    } else if (invalidInputs.length === 1) {
      callback(invalidInputs.join('') + ' is not a valid email');
    } else {
      callback(
        invalidInputs.slice(0, -1).join(', ') +
          ' and ' +
          invalidInputs.slice(-1) +
          ' are not valid emails'
      );
    }
  };

  return (
    <Modal
      title={isEdit ? 'Update existing token' : 'Create a new token'}
      visible={true}
      footer={null}
      centered
      closable={false}
      maskClosable={false}
      destroyOnClose
      width='1000px'>
      <Spin spinning={loadingDetails || loadingLabBundles}>
        <Form
          form={form}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          labelAlign='left'
          onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                wrapperCol={{ span: 24 }}
                label=''
                name='labBundleId'
                colon={false}
                rules={[{ required: true, message: 'Please select lab bundle!' }]}>
                <Select
                  showSearch
                  filterOption={filterOption}
                  placeholder='Select Lab bundle'
                  onSelect={selectLabBundle}>
                  {lbOptions}
                </Select>
              </Form.Item>
              <Form.Item label='Custom name' name='customName' colon={false}>
                <Input />
              </Form.Item>

              <Form.Item
                label='Student'
                name='studentId'
                colon={false}
                rules={[{ required: true, message: 'Please select student!' }]}>
                <Select showSearch filterOption={filterOption} placeholder='Select student'>
                  {studentOptions}
                </Select>
              </Form.Item>

              <Form.Item
                label='Turn on notifications'
                name='notificationEnabled'
                colon={false}
                initialValue={true}
                valuePropName='checked'>
                <Switch size='small' />
              </Form.Item>
              <Form.Item
                label='Customer email'
                name='customerEmails'
                colon={false}
                rules={[
                  {
                    validator: emailValidator,
                  },
                ]}>
                <Select mode='tags' open={false} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='Select Timezone'
                name='timezone'
                colon={false}
                initialValue={timezone}>
                <Select showSearch filterOption={filterOption}>
                  {timezones.map((zone: Timezone) => (
                    <Option value={zone.value} key={zone.value}>
                      {zone.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <DayForm form={form} details={details} />
              <Form.Item label='Token Minutes' name='tokenAmountMinutes' colon={false}>
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>

          <Space className='flex-center margin-top-24'>
            <Button onClick={onCancel}>Cancel</Button>
            {!isEdit ? (
              <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                {() => (
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={loading}
                    disabled={
                      !form.isFieldsTouched(['labBundleId', 'tokenAmountMinutes'], true) ||
                      !form.isFieldsTouched(['studentId'], true) ||
                      form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                      loading
                    }>
                    Create
                  </Button>
                )}
              </Form.Item>
            ) : (
              <>
                <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={loading}
                    disabled={
                      !form.isFieldsTouched(
                        ['labBundleId', 'studentId', 'tokenAmountMinutes'],
                        false
                      ) ||
                      form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                      loading
                    }>
                    Update
                  </Button>
                </Form.Item>
                {isBetween(newDate(), details?.startDate, details?.endDate) ? (
                  <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                    <Popconfirm
                      placement='top'
                      title='Are you sure you want to end token now?'
                      icon={<StopOutlined style={{ color: 'red' }} />}
                      onConfirm={endToken}
                      okText='Yes'
                      cancelText='No'>
                      <Button type='primary' loading={loadingEnd} danger icon={<StopOutlined />}>
                        End
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                ) : (
                  <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                    <Popconfirm
                      placement='top'
                      title='Are you sure you want to delete token lab?'
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={deleteToken}
                      okText='Yes'
                      cancelText='No'>
                      <Button
                        type='primary'
                        loading={loadingDelete}
                        danger
                        icon={<DeleteOutlined />}>
                        Delete
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </>
            )}
          </Space>
        </Form>
      </Spin>
    </Modal>
  );
};

export default TokenNewLabModal;
