import { RootState } from '../../index';

export const getStatus = (state: RootState) => {
  return state.siDevice.status;
};

export const getCurrentPage = (state: RootState) => {
  return state.siDevice.page.currentPage;
};

export const getPage = (state: RootState) => {
  return state.siDevice.page;
};

export const getRequest = (state: RootState) => {
  return state.siDevice.request;
};

export const getData = (state: RootState) => {
  return state.siDevice.data;
};
