import React, { useMemo, useState } from 'react';
import { Button, Card } from 'antd';
import { Link } from 'react-router-dom';
import useTimezone from '../hooks/useTimezone';
import DevicesModal from './modal/DevicesModal';
import { ScheduleStudent } from '../model/interfaces/student';

const boldStyle = {
  fontWeight: 'bold',
};

const StudentLabCard = ({ schedule }: { schedule: ScheduleStudent }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { formatUtcDate, datePickerFormat, timePickerFormat, addTimezoneOnDate, newDate } =
    useTimezone();

  const labDates = schedule.dates.map((d: any, i: number) => {
    const startDate = addTimezoneOnDate(d.startDate).format(datePickerFormat);
    const endDate = addTimezoneOnDate(d.endDate).format(datePickerFormat);
    const endTime = addTimezoneOnDate(d.endDate).format(timePickerFormat);

    return (
      <div key={i}>
        {schedule.timeByDay && <span style={{ fontWeight: 'bold' }}>Day {i + 1}: </span>}
        <span>
          {formatUtcDate(d.startDate)} -{' '}
          {startDate === endDate ? endTime : formatUtcDate(d.endDate)}
        </span>
      </div>
    );
  });

  const active = useMemo(() => {
    if (schedule?.dates) {
      let hasAnyActive = false;
      schedule.dates.forEach((d) => {
        if (newDate().isBetween(newDate(d.startDate), newDate(d.endDate))) {
          hasAnyActive = true;
        }
      });
      return hasAnyActive;
    }
    return false;
  }, [schedule.dates]);

  return (
    <Card
      title={schedule.description}
      headStyle={boldStyle}
      actions={[
        <Button type='primary' key='details' disabled={!active}>
          <Link to={`/home/lab/${schedule.id}/access`}>Access Lab</Link>
        </Button>,
      ]}>
      <div>
        <div className='m-b-16'>{labDates}</div>
        <span style={boldStyle}>Instructor: {schedule.instructor}</span>
      </div>
      {modalVisible && (
        <DevicesModal
          onCancel={() => {
            setModalVisible(false);
          }}
          exerciseEquipment={schedule.exercise.exerciseEquipments}
        />
      )}
    </Card>
  );
};

export default StudentLabCard;
