import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Tabs } from 'antd';
import useAxios from '../../../hooks/useAxios';
import LabTab from './LabTab';
import DeviceControlTab from './DeviceControlTab';
import { useKeycloak } from '@react-keycloak/web';
import { Roles } from '../../../model/enum/Roles';
import { useSelector } from 'react-redux';
import { getDeviceStatus } from '../../../store/slices/app/selectors';

const { TabPane } = Tabs;

const LabAccessPage = () => {
  const { id, tab }: any = useParams();
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const { result, loading, run } = useAxios();
  const [currentTab, setCurrentTab] = useState(tab);
  const [refresh, setRefresh] = useState(false);
  const [schedule, setSchedule] = useState();
  const deviceStatus = useSelector(getDeviceStatus);

  useEffect(() => {
    if (id) {
      refreshDetails();
    }
  }, [id]);

  useEffect(() => {
    if (result) {
      result?.lab?.devices.forEach((d: any) => {
        if (deviceStatus[d.physicalDeviceName]) {
          d.progress = deviceStatus[d.physicalDeviceName].progress;
          d.status = deviceStatus[d.physicalDeviceName].status;
        }
      });
      setSchedule({ ...result });
    }
  }, [result, deviceStatus]);

  useEffect(() => {
    if (tab) {
      setCurrentTab(tab);
    }
  }, [tab]);

  const refreshDetails = () => {
    run({
      url: '/schedule/scheduled/lab/' + id,
    });
  };

  const changeTab = (key: string) => {
    if (keycloak.hasRealmRole(Roles.STUDENT)) {
      history.replace(`/home/lab/${id}/${key}`);
    } else {
      history.replace(`/home/lab-details/lab/${id}/${key}`);
    }
    if (key === 'access') {
      if (refresh) {
        refreshDetails();
        setRefresh(false);
      }
    }
  };

  return (
    <Card bodyStyle={{ paddingTop: 0 }}>
      <Tabs activeKey={currentTab} onTabClick={changeTab}>
        <TabPane tab='Lab' key='access'>
          <LabTab schedule={schedule} isLoading={loading} />
        </TabPane>
        <TabPane tab='Device Control' key='device-control'>
          <DeviceControlTab
            schedule={schedule}
            isLoading={loading}
            refresh={() => setRefresh(true)}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default LabAccessPage;
