import { RootState } from '../../index';
import appSlice from './appState';

export const getFullName = (state: RootState) =>
  state.app.user && state.app.user.firstName && state.app.user.lastName
    ? `${state.app.user.firstName} ${state.app.user.lastName}`
    : null;

export const getUser = (state: RootState) => state.app.user;

export const getUsername = (state: RootState) => state.app.user?.username;

export const getToken = (state: RootState) => state.app.token;

export const getTimezone = (state: RootState) => state.app.timezone;

export const getStatus = (state: RootState) => state.app.status;

export const getTimeFormat = (state: RootState) => state.app.timeFormat;

export const getDateFormat = (state: RootState) => state.app.dateFormat;

export const getRdpConnection = (state: RootState) => state.app.rdpConnection;

export const getDeviceStatus = (state: RootState) => state.app.deviceStatus;

export const appActions = appSlice.actions;

export default appSlice;
