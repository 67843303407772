import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Row, Select, Space, Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import useNotification from '../../hooks/useNotification';
import useAxios from '../../hooks/useAxios';
import HttpMethod from '../../model/enum/HttpMethod';
import { LabTopology, LogicalDevice } from '../../model/interfaces/labTopology';
import { useDispatch, useSelector } from 'react-redux';
import { ucDevicesCodes } from '../../store/slices/codes/selectors';
import { getUCDevicesCodes } from '../../store/slices/codes/extraReducers';
import { filterOption } from '../../util/helperUtils';

const { Option } = Select;
const { Title } = Typography;

const NewLab = () => {
  const dispatch = useDispatch();
  const [labTopology, setLabTopology] = useState<LabTopology>();
  const [form] = Form.useForm();
  const { id }: any = useParams();
  const isEdit = !!id;
  const ucDevices = useSelector(ucDevicesCodes);
  const { successNotification } = useNotification();
  const { result, loading, run } = useAxios();
  const { result: details, loading: loadingDetails, run: getDetails } = useAxios();
  const {
    result: labTopologies,
    loading: loadingLabTopologies,
    run: getLabTopologies,
  } = useAxios();

  useEffect(() => {
    getLabTopologies({
      url: 'lab-topology/all',
    });
    dispatch(getUCDevicesCodes());
  }, []);

  useEffect(() => {
    if (isEdit) {
      getDetails({
        url: `lab/${id}`,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (details && labTopologies) {
      selectLabTopology(details.labTopology.id);
      form.setFieldsValue({
        ...details,
        labTopologyId: details.labTopology.id,
      });
    }
  }, [details, labTopologies]);

  useEffect(() => {
    if (result) {
      if (isEdit) {
        successNotification('Lab updated');
      } else {
        successNotification('Lab created');
      }
      history.back();
    }
  }, [result]);

  const onFinish = (values: any) => {
    if (isEdit) {
      const workgroupDevices = labTopology.logicalDevices.map((d: LogicalDevice, index) => {
        return {
          logicalDeviceId: d.id,
          logicalName: d.logicalName,
          physicalDeviceId: values.workgroupDevices[index].physicalDeviceId,
        };
      });
      run({
        url: 'lab/update',
        method: HttpMethod.PUT,
        body: {
          id: isEdit ? id : null,
          ...details,
          ...values,
          workgroupDevices,
        },
      });
    } else {
      run({
        url: 'lab/create',
        method: HttpMethod.POST,
        body: values,
      });
    }
  };

  const labTopologyOptions = labTopologies?.map((device: any) => {
    return (
      <Option value={device.id} key={device.id}>
        {device.name}
      </Option>
    );
  });

  const selectLabTopology = (labTopologyId: string) => {
    const lt = labTopologies.find((t: LabTopology) => t.id === labTopologyId);
    setLabTopology(lt);
    form.setFieldsValue({
      workgroupDevices: lt.logicalDevices.map((d: LogicalDevice) => {
        return {
          logicalDeviceId: d.id,
          logicalName: d.logicalName,
        };
      }),
    });
  };

  const ucDevicesOptions = ucDevices?.map((device: any) => {
    return (
      <Option value={device.value} key={device.value}>
        {device.label}
      </Option>
    );
  });

  return (
    <Spin spinning={loading || loadingDetails}>
      <Card>
        <Form
          form={form}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          labelAlign='left'
          onFinish={onFinish}>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Title level={4}>Basic Lab Data</Title>
              <Form.Item
                label='Lab name'
                name='name'
                colon={false}
                rules={[{ required: true, message: 'Please enter lab name!' }]}>
                <Input />
              </Form.Item>
              <Form.Item label='Lab Description' name='description' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item
                label='Remote Lab Topology'
                name='labTopologyId'
                colon={false}
                rules={[{ required: true, message: 'Please choose remote lab topology!' }]}>
                <Select
                  showSearch
                  loading={loadingLabTopologies}
                  placeholder={'Select lab topology'}
                  onSelect={selectLabTopology}
                  filterOption={filterOption}>
                  {labTopologyOptions}
                </Select>
              </Form.Item>
              <Form.Item label='Workgroup' name='workgroup' colon={false}>
                <Input type='number' min='1' />
              </Form.Item>
              <Form.Item label='Workgroup Description' name='workgroupDescription' colon={false}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Title level={4}>Lab Device Mapping</Title>
              {labTopology?.logicalDevices?.length > 0 && (
                <>
                  <Form.List name='workgroupDevices'>
                    {(fields: any) => (
                      <>
                        {fields.map((field: any) => (
                          <Form.Item
                            key={field.key}
                            colon={false}
                            name={[field.name, 'physicalDeviceId']}
                            fieldKey={[field.fieldKey, 'physicalDeviceId']}
                            label={labTopology?.logicalDevices[field.key].logicalName}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Please select device!',
                              },
                            ]}>
                            <Select
                              showSearch
                              placeholder='Select device'
                              filterOption={filterOption}>
                              {ucDevicesOptions}
                            </Select>
                          </Form.Item>
                        ))}
                      </>
                    )}
                  </Form.List>
                </>
              )}
            </Col>
          </Row>

          <Space className='flex-center margin-top-24'>
            <Button onClick={() => history.back()}>Cancel</Button>
            {!isEdit && (
              <Button type='primary' htmlType='submit'>
                Create
              </Button>
            )}
            {isEdit && (
              <Button type='primary' htmlType='submit'>
                Save
              </Button>
            )}
          </Space>
        </Form>
      </Card>
    </Spin>
  );
};

export default NewLab;
