import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { getDateFormat, getTimeFormat, getTimezone } from '../store/slices/app/selectors';
import { DateFormat, TimeFormat } from '../model/interfaces/app';
import { useMemo } from 'react';

const useTimezone = () => {
  const timezone = useSelector(getTimezone);
  const timeFormat = useSelector(getTimeFormat);
  const dateFormat = useSelector(getDateFormat);

  const calculateOffset = (tz: any) => {
    let offset = 0;
    if (!tz) {
      tz = timezone;
    }
    if (tz) {
      offset = newDate().tz(tz).utcOffset();
    }
    return offset;
  };

  const guessedTimezone = dayjs.tz.guess();

  const addTimezoneOnDate = (value: any, tz?: string) => {
    return newDate(value).add(calculateOffset(tz), 'minute');
  };

  const removeTimezoneFromDate = (value: any, tz?: string) => {
    return newDate(value).subtract(calculateOffset(tz), 'minute');
  };

  const newDate = (date?: any) => {
    return dayjs.utc(date);
  };

  const isBetween = (date: any, startDate: any, endDate: any) => {
    if (!date || !startDate || !endDate) {
      return false;
    }

    return newDate(date).isBetween(newDate(startDate), newDate(endDate), 'milliseconds', '[]');
  };

  const formatUtcDate = (date: Date | Dayjs): string => {
    return formatDate(addTimezoneOnDate(date));
  };

  const formatDate = (date: Date | Dayjs): string => {
    let dayjsDate = newDate(date);

    if (dateFormat === DateFormat.DOT_SEPARATED && timeFormat === TimeFormat.H24) {
      return dayjsDate.format('DD.MM.YYYY HH:mm');
    } else if (dateFormat === DateFormat.DOT_SEPARATED && timeFormat === TimeFormat.H12) {
      return dayjsDate.format('DD.MM.YYYY hh:mm a');
    } else if (dateFormat === DateFormat.SLASH_SEPARATED && timeFormat === TimeFormat.H24) {
      return dayjsDate.format('MM/DD/YYYY HH:mm');
    } else if (dateFormat === DateFormat.SLASH_SEPARATED && timeFormat === TimeFormat.H12) {
      return dayjsDate.format('MM/DD/YYYY hh:mm a');
    } else if (dateFormat === DateFormat.DASH_SEPARATED && timeFormat === TimeFormat.H24) {
      return dayjsDate.format('YYYY-MM-DD HH:mm');
    } else if (dateFormat === DateFormat.DASH_SEPARATED && timeFormat === TimeFormat.H12) {
      return dayjsDate.format('YYYY-MM-DD hh:mm a');
    } else {
      return dayjsDate.format('DD.MM.YYYY HH:mm');
    }
  };

  const getDatesBetween = (start: any, end: any) => {
    const daysBetween = newDate(end).diff(newDate(start), 'days');
    let days = [newDate(start)];
    for (let i = 0; i < daysBetween - 1; i++) {
      const nextDay = days[i].add(1, 'day');
      days.push(nextDay);
    }

    return days;
  };

  const datePickerFormat = useMemo(() => {
    if (dateFormat === DateFormat.DOT_SEPARATED) {
      return 'DD.MM.YYYY';
    } else if (dateFormat === DateFormat.SLASH_SEPARATED) {
      return 'MM/DD/YYYY';
    } else if (dateFormat === DateFormat.DASH_SEPARATED) {
      return 'YYYY-MM-DD';
    } else {
      return 'DD.MM.YYYY';
    }
  }, [dateFormat]);

  const timePickerFormat = useMemo(() => {
    if (timeFormat === TimeFormat.H24) {
      return 'HH:mm';
    } else {
      return 'hh:mm a';
    }
  }, [timeFormat]);

  return {
    guessedTimezone,
    formatDate,
    formatUtcDate,
    newDate,
    addTimezoneOnDate,
    removeTimezoneFromDate,
    getDatesBetween,
    isBetween,
    datePickerFormat,
    timePickerFormat,
  };
};

export default useTimezone;
