import React, { useEffect } from 'react';
import { Button, Card, Col, Form, Input, Row, Select, Space, Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import useNotification from '../../hooks/useNotification';
import useAxios from '../../hooks/useAxios';
import HttpMethod from '../../model/enum/HttpMethod';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceTypeCodes } from '../../store/slices/codes/extraReducers';
import { deviceTypeCodes } from '../../store/slices/codes/selectors';
import { filterOption } from '../../util/helperUtils';

const { Title } = Typography;
const { Option } = Select;

const NewSIDevice = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id }: any = useParams();
  const { successNotification } = useNotification();
  const { result, loading, run } = useAxios();
  const { result: details, loading: loadingDetails, run: getDetails } = useAxios();
  const deviceTypes = useSelector(deviceTypeCodes);
  const isEdit = !!id;

  useEffect(() => {
    dispatch(getDeviceTypeCodes());
  }, []);

  useEffect(() => {
    if (isEdit) {
      getDetails({
        url: `si-device/${id}`,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (details) {
      form.setFieldsValue({
        ...details,
        typeId: details.type.id,
      });
    }
  }, [details]);

  useEffect(() => {
    if (result) {
      if (isEdit) {
        successNotification('Device updated');
      } else {
        successNotification('Device created');
      }
      history.back();
    }
  }, [result]);

  const onFinish = (values: any) => {
    if (isEdit) {
      run({
        url: 'si-device/update',
        method: HttpMethod.PUT,
        body: {
          id: isEdit ? id : null,
          ...details,
          ...values,
        },
      });
    } else {
      run({
        url: 'si-device/create',
        method: HttpMethod.POST,
        body: values,
      });
    }
  };

  const deviceTypesOptions = deviceTypes?.map((type: any) => {
    return (
      <Option value={type.value} key={type.value}>
        {type.label}
      </Option>
    );
  });

  return (
    <Spin spinning={loading || loadingDetails}>
      <Card>
        <Form form={form} labelCol={{ span: 12 }} labelAlign='left' onFinish={onFinish}>
          <Title level={4}>Basic Data</Title>
          <Row gutter={64}>
            <Col span={24} md={{ span: 12 }}>
              <Form.Item
                label='Name'
                name='name'
                colon={false}
                rules={[{ required: true, message: 'Please enter name!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label='Device Role'
                name='role'
                colon={false}
                rules={[{ required: true, message: 'Please enter type!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label='Type'
                name='typeId'
                colon={false}
                rules={[{ required: true, message: 'Please select device type!' }]}>
                <Select showSearch filterOption={filterOption} placeholder='Select device type'>
                  {deviceTypesOptions}
                </Select>
              </Form.Item>
              <Form.Item label='Access Method' name='accessMethod' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Management IP' name='managementIp' colon={false}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label='URL' name='url' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Console Host' name='consoleHost' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Console Line' name='consoleLine' colon={false}>
                <Input type='number' min='1' />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label='Extra Line name' name='consoleExtraLineName' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Console Port' name='consolePort' colon={false}>
                <Input type='number' min='1' />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label='Access TS Vrf' name='accessTsVrf' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Username' name='username' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Password' name='accessPassword' colon={false}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label='Enable Password' name='enablePassword' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={64} className='margin-top-24'>
            <Col span={24} md={12}>
              <Title level={4}>Virtual Center Data</Title>
              <Form.Item label='Datacenter' name='dataCenter' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='Resource Pool' name='resourcePool' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='Compute Resource' name='computeResource' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='Folder' name='folder' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='ISO Folder' name='isoFolder' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='Monitor User' name='monitorUser' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='Monitor Folders' name='monitorFolders' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='ESXi Username' name='esxiUsername' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='ESXi Password' name='esxiPassword' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='VM Datastores' name='vmDatastores' colon={false}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Title level={4}>Other Data</Title>
              <Form.Item label='Extra Settings' name='extraSettings' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='Comment' name='comment' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Space className='flex-center margin-top-24'>
            <Button onClick={() => history.back()}>Cancel</Button>
            {!isEdit && (
              <Button type='primary' htmlType='submit'>
                Create
              </Button>
            )}
            {isEdit && (
              <Button type='primary' htmlType='submit'>
                Save
              </Button>
            )}
          </Space>
        </Form>
      </Card>
    </Spin>
  );
};

export default NewSIDevice;
