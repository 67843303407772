import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, roles, ...restOfProps }: any) => {
  const { keycloak } = useKeycloak();

  const isAuthorized = (roleList: string[]) => {
    if (keycloak && roleList) {
      return roleList.some((r) => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };

  return (
    <Route
      {...restOfProps}
      render={(props) => (isAuthorized(roles) ? <Component {...props} /> : <Redirect to='/home' />)}
    />
  );
};

export default PrivateRoute;
