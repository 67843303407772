import { createAsyncThunk } from '@reduxjs/toolkit';
import { BasePageRequest } from '../../../model/interfaces/shared/basePageRequest';
import { LabFilterRequest, LabState } from '../../../model/interfaces/lab';
import { filterLabApi } from '../../../service/LabService';
import { Status } from '../../../model/enum/Status';

export const filterLab = createAsyncThunk(
  'getLab',
  async (command: BasePageRequest<LabFilterRequest>) => {
    const response = await filterLabApi(command);
    return response.data;
  }
);

export const extraReducers = (builder: any) => {
  builder.addCase(filterLab.pending, (state: LabState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(filterLab.fulfilled, (state: LabState, action: any) => {
    state.status = Status.SUCCESS;
    state.page = action.payload.page;
    state.data = action.payload.response;
  });
  builder.addCase(filterLab.rejected, (state: LabState) => {
    state.status = Status.ERROR;
    state.data = [];
  });
};
