import React, { useCallback, useEffect, useRef, useState } from 'react';
import Guacamole from 'guacamole-common-js';
import { GUACAMOLE_CLIENT_STATES, GUACAMOLE_STATUS } from '../model/guacamole-const';
import { Button, Card, Typography } from 'antd';
import LabLoader from './LabLoader';
import config from '../util/config';

const { Title } = Typography;

interface GuacamoleClientProps {
  wsPath?: string;
  controlSize?: boolean;
  controlInput?: boolean;
  screenSize?: {
    width: number;
    height: number;
  };
  labId: string;
}

const GuacamoleClient = ({
  wsPath = config.websocketUrl + '/guacamole',
  controlSize = true,
  controlInput = true,
  screenSize = null,
  labId,
}: GuacamoleClientProps) => {
  const displayRef = useRef<any>(null);
  const guacRef = useRef<any>(null);
  const connectParamsRef = useRef<any>({});
  const scale = useRef<any>(1);
  const demandedScreenSize = useRef<any>(0);

  // Timer which controls timeout for display size update
  const updateDisplaySizeTimerRef = useRef<any>(0);

  const [clientState, setClientState] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);

  const getConnectionString = () => {
    let params: any = connectParamsRef.current;
    return Object.keys(params)
      .map((key) => {
        if (Array.isArray(params[key])) {
          return params[key]
            .map((item: any) => encodeURIComponent(key) + '=' + encodeURIComponent(item))
            .join('&');
        }
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      })
      .join('&');
  };

  // updates scale factor given new actual display width/height
  const rescaleDisplay = useCallback(() => {
    // get current width/height of connection
    let remoteDisplayWidth = guacRef.current.getDisplay().getWidth();
    let remoteDisplayHeight = guacRef.current.getDisplay().getHeight();

    if (!displayRef.current) {
      return;
    }

    let newWidth = displayRef.current.offsetWidth;
    let newHeight = displayRef.current.offsetHeight;

    // calculate which scale should we use - width or height, in order to see all of remote display
    let newScale = Math.min(newWidth / remoteDisplayWidth, newHeight / remoteDisplayHeight, 1);

    guacRef.current.getDisplay().scale(newScale);
    scale.current = newScale;
  }, []);

  // Display size update handler, currently implement onli logging to console
  const updateDisplaySize = useCallback(
    (timeout?: number, widthParam?: number, heightParam?: number) => {
      if (!guacRef.current) return;

      // If we have resize scheduled - cancel it, because we received new insructions
      if (updateDisplaySizeTimerRef.current) {
        clearTimeout(updateDisplaySizeTimerRef.current);
      }

      let newDisplayWidth = 0;
      let newDisplayHeight = 0;

      // Timeout to 500 ms, so that size is updated 0.5 second after resize ends
      updateDisplaySizeTimerRef.current = setTimeout(
        () => {
          // if we are provided with widthParam/heightParam upfront - use them
          if (widthParam > 0 && heightParam > 0) {
            newDisplayWidth = widthParam;
            newDisplayHeight = heightParam;
          } else if (displayRef.current) {
            // otherwise we can measure client size of display element and use it
            // this is usually needed when we are connecting

            newDisplayWidth = displayRef.current.clientWidth;
            newDisplayHeight = displayRef.current.clientHeight;
          }

          // save new width/height for reconnect purposes
          connectParamsRef.current.width = newDisplayWidth;
          connectParamsRef.current.height = newDisplayHeight;

          if (newDisplayWidth > 1 && newDisplayHeight > 1) {
            if (controlSize) {
              if (demandedScreenSize.current) {
                guacRef.current.sendSize(
                  demandedScreenSize.current.width,
                  demandedScreenSize.current.height
                );
              } else {
                guacRef.current.sendSize(newDisplayWidth, newDisplayHeight);
              }

              // we sent resize command and possiblty resolution will be update
              // take a timeout to see the updated resolution of GuacamoleClient dispalay
              setTimeout(() => {
                rescaleDisplay();
              }, 500);
            } else {
              // We do not have control over display size, it means GuacamoleClient display will not change
              // so we can rescale display right away
              rescaleDisplay();
            }
          }
        },
        timeout > 0 ? timeout : 500
      );
    },
    [controlSize, rescaleDisplay]
  );

  // Main effect which constructs GuacamoleClient
  // should reaaaly be run only once
  useEffect(() => {
    // Determine websocket URI
    // const protocolPrefix = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    // let { host } = window.location;
    // let webSocketFullUrl = `${protocolPrefix}//${host}${wsPath}`;
    guacRef.current = new Guacamole.Client(new Guacamole.WebSocketTunnel(wsPath));

    displayRef.current.innerHTML = '';
    displayRef.current.appendChild(guacRef.current.getDisplay().getElement());
    displayRef.current.focus();

    // Error handler
    guacRef.current.onerror = (error: any) => {
      let msg = error.message;

      if (GUACAMOLE_STATUS[error.code]) {
        msg = GUACAMOLE_STATUS[error.code].text;
      }

      setErrorMessage(msg);
    };

    // Update state, component knows when to render faders, "Loading..." and so on
    guacRef.current.onstatechange = (newState: any) => {
      setClientState(newState);
    };

    // Setup connection parameters, like resolution and supported audio types
    let connectionParams: any = {
      labId,
      audio: [],
    };

    // if current instance is allowed to control remote display size - include window size in connection info
    if (controlSize) {
      connectionParams.width = displayRef.current.clientWidth;
      connectionParams.height = displayRef.current.clientHeight;
    }

    let supportedAudioTypes = Guacamole.AudioPlayer.getSupportedTypes();
    if (supportedAudioTypes && supportedAudioTypes.length > 0) {
      connectionParams.audio = supportedAudioTypes.map((item) => item + ';rate=44100,channels=2');
    }

    // Set connection parameters as we will use them later to reconnect
    connectParamsRef.current = connectionParams;

    // Everything has been setup - we can initiate connection
    guacRef.current.connect(getConnectionString());

    // Specify how to clean up after this effect:
    return () => {
      // Disconnect Guacamole Client, so server know'w we don't need any updates and teminates connection
      // to server
      guacRef.current.disconnect();
    };
  }, [wsPath, updateDisplaySize, controlSize]);

  // This effect fires when "screenSize" prop has changed, which mean either
  // demanded resolution was change of set to Auto
  useEffect(() => {
    demandedScreenSize.current = screenSize;

    if (screenSize) {
      updateDisplaySize(100, demandedScreenSize.current.width, demandedScreenSize.current.height);
    } else {
      updateDisplaySize();
    }
  }, [updateDisplaySize, screenSize]);

  // This effect creates Guacamole.Keyboard / Guacamole.Mouse on current display element and binds callbacks
  // to current guacamole client
  useEffect(() => {
    // don't bind to events if we know this input will not be accepted at server side
    if (!controlInput) {
      return;
    }

    // Keyboard
    let keyboard = new Guacamole.Keyboard(displayRef.current);

    const fixKeys = (keysym: any) => {
      // 65508 - Right Ctrl
      // 65507 - Left Ctrl
      // somehow Right Ctrl is not sent, so send Left Ctrl instead
      if (keysym === 65508) return 65507;

      return keysym;
    };

    keyboard.onkeydown = (key: number) => {
      guacRef.current.sendKeyEvent(1, fixKeys(key));
    };

    keyboard.onkeyup = (key: number) => {
      guacRef.current.sendKeyEvent(0, fixKeys(key));
    };

    // Mouse
    let mouse = new Guacamole.Mouse(displayRef.current);

    mouse.onmousemove = (mouseState) => {
      mouseState.x = mouseState.x / scale.current;
      mouseState.y = mouseState.y / scale.current;
      guacRef.current.sendMouseState(mouseState);
    };

    mouse.onmousedown = mouse.onmouseup = (mouseState) => {
      guacRef.current.sendMouseState(mouseState);
    };
  }, [controlInput]);

  // Thi effect  binds to server side resize event
  useEffect(() => {
    if (!controlSize) {
      guacRef.current.getDisplay().onresize = (x: number, y: number) => {
        updateDisplaySize(0, x, y);
      };
    }
  }, [controlSize, updateDisplaySize]);

  const reconnect = () => {
    setErrorMessage(null);
    guacRef.current.connect(getConnectionString());
  };

  useEffect(() => {
    // Subscribe to FlexLayout node resize event.
    // This will provide use updated size of visible recangle
    // Event is fired before actual resize happens, and provides with new dimensions (rect)
    const updateDisplaySizeCallback = (rect: any) => {
      updateDisplaySize(0, rect.width, rect.height);
    };

    window.addEventListener('resize', updateDisplaySizeCallback);

    // Specify how to cleanup after this effect
    return () => {
      window.removeEventListener('resize', updateDisplaySizeCallback);
    };
  }, [updateDisplaySize]);

  const onDisplayClick = () => {
    displayRef.current.focus();
  };

  // This effect manages subscribing to clipboard events to manage clipboard synchronization
  useEffect(() => {
    const handleServerClipboardChange = (stream: any, mimetype: string) => {
      // don't do anything if this is not active element
      if (document.activeElement !== displayRef.current) return;

      if (mimetype === 'text/plain') {
        // stream.onblob = (data) => copyToClipboard(atob(data));
        stream.onblob = (data: any) => {
          let serverClipboard = atob(data);
          // we don't want action if our knowledge of server cliboard is unchanged
          // and also don't want to fire if we just selected several space character accidentaly
          // which hapens often in SSH session
          if (serverClipboard.trim() !== '') {
            // put data received form server to client's clipboard
            navigator.clipboard.writeText(serverClipboard);
          }
        };
      }
    };

    // Read client's clipboard
    const onFocusHandler = () => {
      // when focused, read client clipboard text
      navigator.clipboard.readText().then((clientClipboard) => {
        let stream = guacRef.current.createClipboardStream('text/plain', 'clipboard');
        setTimeout(() => {
          // remove '\r', because on pasting it becomes two new lines (\r\n -> \n\n)
          stream.sendBlob(
            btoa(unescape(encodeURIComponent(clientClipboard.replace(/[\r]+/gm, ''))))
          );
        }, 200);
      });
    };

    // add handler only when navigator clipboard is available
    if (navigator.clipboard) {
      displayRef.current.addEventListener('focus', onFocusHandler);
      guacRef.current.onclipboard = handleServerClipboardChange;
    }
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}>
      <div
        className='flex-center guacamole-display'
        ref={displayRef}
        onClick={onDisplayClick}
        tabIndex={0}
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          cursor: clientState === GUACAMOLE_CLIENT_STATES.STATE_CONNECTED ? 'none' : 'default',
          position: 'relative',
        }}
      />
      {clientState < GUACAMOLE_CLIENT_STATES.STATE_CONNECTED && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            paddingTop: '40px',
            transform: 'translate(-50%, -50%)',
            background: 'white',
          }}>
          <LabLoader message='Connecting...' percentage={100} devices={[]} />
        </div>
      )}
      {(clientState > GUACAMOLE_CLIENT_STATES.STATE_CONNECTED || errorMessage) && (
        <div
          style={{
            background: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <Card
            actions={[
              <Button key='reconnect' onClick={reconnect}>
                Reconnect
              </Button>,
            ]}>
            <Title level={5}>Session disconnected</Title>
            {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
          </Card>
        </div>
      )}
    </div>
  );
};

export default GuacamoleClient;
