import React, { useEffect } from 'react';
import { Card, Empty, List, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentPage,
  getData,
  getPage,
  getRequest,
  getStatus,
} from '../../../store/slices/student/selectors';
import { itemRender } from '../../../components/shared/TableItemRender';
import { Status } from '../../../model/enum/Status';
import { filterStudentLab } from '../../../store/slices/student/extraReducers';
import { studentActions } from '../../../store/slices/student/state';
import StudentLabCard from '../../../components/StudentLabCard';
import { getUsername } from '../../../store/slices/app/selectors';

const StudentHomepage = () => {
  const dispatch = useDispatch();
  const data = useSelector(getData);
  const page = useSelector(getPage);
  const status = useSelector(getStatus);
  const request = useSelector(getRequest);
  const currentPage = useSelector(getCurrentPage);
  const username = useSelector(getUsername);

  useEffect(() => {
    fetchData();
  }, [request, currentPage, page.sortProperty, page.sortDirection]);

  const fetchData = () => {
    if (username) {
      dispatch(
        filterStudentLab({
          request: {
            username,
            ...request,
          },
          ...page,
        })
      );
    }
  };

  const onChange = (page: number) => {
    dispatch(studentActions.setCurrentPage({ current: page }));
  };

  return (
    <Spin spinning={status === Status.LOADING}>
      <>
        {data.length === 0 && (
          <Card>
            <Empty />
          </Card>
        )}
        {data.length > 0 && (
          <List
            pagination={{
              onChange,
              size: 'small',
              itemRender,
              current: currentPage + 1,
              pageSize: page?.size,
              total: page?.totalElements,
              showSizeChanger: false,
            }}
            grid={{
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 3,
              gutter: 16,
            }}
            dataSource={data}
            renderItem={(schedule) => {
              return (
                <List.Item key={schedule.id}>
                  <StudentLabCard schedule={schedule} />
                </List.Item>
              );
            }}
          />
        )}
      </>
    </Spin>
  );
};

export default StudentHomepage;
