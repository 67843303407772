import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import appSlice from './slices/app/appState';
import labTopologySlice from './slices/labTopology/state';
import labSlice from './slices/lab/state';
import ucDeviceSlice from './slices/ucDevice/state';
import codesSlice from './slices/codes/state';
import siDeviceSlice from './slices/siDevice/state';
import exerciseSlice from './slices/exercise/state';
import labBundleSlice from './slices/labBundle/state';
import scheduleSlice from './slices/schedule/state';
import studentSlice from './slices/student/state';
import deviceTypeSlice from './slices/deviceType/state';
import tokenSlice from './slices/token/state';

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    codes: codesSlice.reducer,
    deviceType: deviceTypeSlice.reducer,
    labTopology: labTopologySlice.reducer,
    lab: labSlice.reducer,
    ucDevice: ucDeviceSlice.reducer,
    siDevice: siDeviceSlice.reducer,
    exercise: exerciseSlice.reducer,
    labBundle: labBundleSlice.reducer,
    schedule: scheduleSlice.reducer,
    token: tokenSlice.reducer,
    student: studentSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
