import { BasePageRequest } from '../model/interfaces/shared/basePageRequest';
import { apiPost } from './Axios';
import { TokenFilterRequest, TokenLabFilterRequest } from 'model/interfaces/token';

export const filterTokenLabsApi = (request: BasePageRequest<TokenFilterRequest>) => {
  return apiPost(`token/filter`, request);
};

export const filterStudentLabsApi = (request: BasePageRequest<TokenLabFilterRequest>) => {
  return apiPost(`token/filter-student`, request);
};
