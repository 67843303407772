import { createAsyncThunk } from '@reduxjs/toolkit';
import { BasePageRequest } from '../../../model/interfaces/shared/basePageRequest';
import { StudentState } from '../../../model/interfaces/student';
import { Status } from '../../../model/enum/Status';
import { ScheduleLabFilterRequest } from '../../../model/interfaces/schedule';
import { filterStudentLabsApi } from '../../../service/ScheduleService';

export const filterStudentLab = createAsyncThunk(
  'filterStudentLab',
  async (command: BasePageRequest<ScheduleLabFilterRequest>) => {
    const response = await filterStudentLabsApi(command);
    return response.data;
  }
);

export const extraReducers = (builder: any) => {
  builder.addCase(filterStudentLab.pending, (state: StudentState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(filterStudentLab.fulfilled, (state: StudentState, action: any) => {
    state.status = Status.SUCCESS;
    state.page = action.payload.page;
    state.data = action.payload.response;
  });
  builder.addCase(filterStudentLab.rejected, (state: StudentState) => {
    state.status = Status.ERROR;
    state.data = [];
  });
};
