import { RootState } from '../../index';

export const getStatus = (state: RootState) => {
  return state.token.status;
};

export const getCurrentPage = (state: RootState) => {
  return state.token.page.currentPage;
};

export const getPage = (state: RootState) => {
  return state.token.page;
};

export const getRequest = (state: RootState) => {
  return state.token.request;
};

export const getData = (state: RootState) => {
  return state.token.data;
};

export const getUsername = (state: RootState) => {
  return state.token.username;
};
