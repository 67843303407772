import * as React from 'react';
import { Dayjs } from 'dayjs';
import { PickerTimeProps } from 'antd/es/date-picker/generatePicker';
import useTimezone from '../../../hooks/useTimezone';
import { ForwardedRef } from 'react';
import { DayjsDatePicker } from '../DatePicker/DatePicker';

export interface TimePickerProps extends Omit<PickerTimeProps<Dayjs>, 'picker'> {}

const TimePicker = React.forwardRef<any, TimePickerProps>((props: any, ref: ForwardedRef<any>) => {
  const { timePickerFormat } = useTimezone();

  return (
    <DayjsDatePicker
      {...props}
      picker='time'
      mode={undefined}
      format={timePickerFormat}
      minuteStep={5}
      showNow={false}
      ref={ref}
    />
  );
});

TimePicker.displayName = 'TimePicker';

export default TimePicker;
