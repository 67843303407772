import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Switch,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getTimezone } from '../../store/slices/app/selectors';
import { filterOption } from '../../util/helperUtils';
import { Timezone } from '../../model/interfaces/shared/timezone';
import timezones from '../../util/timzones';
import { instructorsCodes } from '../../store/slices/codes/selectors';
import TimeByDayForm from '../shared/TimeByDayForm';
import { DeleteOutlined, QuestionCircleOutlined, StopOutlined } from '@ant-design/icons';
import useAxios from '../../hooks/useAxios';
import { getInstructorsCodes } from '../../store/slices/codes/extraReducers';
import { LabBundle } from '../../model/interfaces/labBundle';
import { User } from '../../model/interfaces/user';
import HttpMethod from '../../model/enum/HttpMethod';
import useNotification from '../../hooks/useNotification';
import { ScheduleDate } from '../../model/interfaces/schedule';
import useTimezone from '../../hooks/useTimezone';

const { Option } = Select;

interface ScheduleNewLab {
  closeModal: (refresh?: boolean) => void;
  startDate: string;
  endDate: string;
  id?: string;
  students?: number;
  labBundleId?: string;
}

const ScheduleNewLabModal = ({
  closeModal,
  startDate,
  endDate,
  id,
  students,
  labBundleId,
}: ScheduleNewLab) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { removeTimezoneFromDate, addTimezoneOnDate, newDate, getDatesBetween, isBetween } =
    useTimezone();
  const timezone = useSelector(getTimezone);
  const [labBundle, setLabBundle] = useState(null);
  const [numberOfStudents, setNumberOfStudents] = useState(null);
  const [numberOfWorkgroups, setNumberOfWorkgroups] = useState(0);
  const instructors = useSelector(instructorsCodes);
  const { successNotification } = useNotification();
  const { result, loading, run } = useAxios();
  const { result: deleteResult, loading: loadingDelete, run: runDelete } = useAxios();
  const { result: endResult, loading: loadingEnd, run: runEnd } = useAxios();
  const { result: details, loading: loadingDetails, run: getDetails } = useAxios();
  const { result: labBundles, loading: loadingLabBundles, run: getLabBundles } = useAxios();
  const {
    result: maxNumberOfStudents,
    loading: loadingMaxNumberOfStudents,
    run: getMaxNumberOfStudents,
  } = useAxios();
  const isEdit = !!id;

  useEffect(() => {
    dispatch(getInstructorsCodes());
    getLabBundles({
      url: 'lab-bundle/all',
    });
    if (startDate && endDate) {
      form.setFieldsValue({
        dates: [
          {
            startDate: newDate(startDate),
            endDate: newDate(endDate),
          },
        ],
      });
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      getDetails({
        url: `schedule/${id}`,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (students) {
      setNumberOfStudents(students);
      form.setFieldsValue({ numberOfStudents: students });
    }
  }, [students]);

  useEffect(() => {
    if (details) {
      const dts = prepareDatesForForm(details.dates, details.timeByDay);
      setNumberOfStudents(details.numberOfStudents);
      form.setFieldsValue({
        ...details,
        dates: dts,
        labBundleId: details.labBundle.id,
      });
    }
  }, [details]);

  useEffect(() => {
    if (details && labBundles) {
      selectLabBundle(details.labBundle.id);
    }
  }, [details, labBundles]);

  useEffect(() => {
    if (maxNumberOfStudents) {
      form.validateFields(['numberOfStudents']);
    }
  }, [maxNumberOfStudents]);

  useEffect(() => {
    if (labBundles && labBundleId) {
      form.setFieldsValue({ labBundleId });
      selectLabBundle(labBundleId);
    }
  }, [labBundles, labBundleId]);

  useEffect(() => {
    if (labBundle && numberOfStudents) {
      if (labBundle?.studentsPerWorkgroup) {
        const no = Math.ceil(numberOfStudents / labBundle.studentsPerWorkgroup);
        setNumberOfWorkgroups(no);
      }
    } else {
      setNumberOfWorkgroups(0);
    }
  }, [labBundle, numberOfStudents]);

  useEffect(() => {
    if (result) {
      if (isEdit) {
        successNotification('Schedule updated');
      } else {
        successNotification('Schedule created');
      }
      closeModal(true);
    }
  }, [result]);

  const onFinish = (values: any) => {
    if (isEdit) {
      run({
        url: 'schedule/update',
        method: HttpMethod.PUT,
        body: {
          ...details,
          ...values,
          id: isEdit ? id : null,
          numberOfWorkgroups,
          dates: convertToUtcDates(values.dates, values.timezone),
        },
      });
    } else {
      run({
        url: 'schedule/create',
        method: HttpMethod.POST,
        body: {
          ...values,
          numberOfWorkgroups,
          dates: convertToUtcDates(values.dates, values.timezone),
        },
      });
    }
  };

  const convertToUtcDates = (dates: Array<ScheduleDate>, timezone: string) => {
    return dates.map((d: ScheduleDate) => {
      return {
        startDate: removeTimezoneFromDate(d.startDate, timezone),
        endDate: removeTimezoneFromDate(d.endDate, timezone),
      };
    });
  };

  const onCancel = () => {
    closeModal();
  };

  const lbOptions = labBundles?.map((lb: LabBundle) => {
    return (
      <Option key={lb.id} value={lb.id}>
        {lb.description}
      </Option>
    );
  });

  const instructorOptions = instructors.map((u: User) => {
    return (
      <Option key={u.id} value={u.id}>
        {`${u.firstName} ${u.lastName}`}
      </Option>
    );
  });

  const selectLabBundle = (id: any) => {
    setLabBundle(labBundles.find((lb: LabBundle) => lb.id === id));
    getMaxNumberOfStudents({
      url: 'lab/max-number-of-students/' + id,
    });
  };

  const selectInstructor = (id: any) => {
    form.setFieldsValue({ instructorEmail: instructors.find((i: any) => i.id === id)?.email });
  };

  const changeNumberOfStudents = (value: any) => {
    setNumberOfStudents(value);
  };

  const deleteSchedule = () => {
    runDelete({
      url: `schedule/delete/`,
      method: HttpMethod.DELETE,
      param: id,
    });
  };

  useEffect(() => {
    if (deleteResult) {
      successNotification('Schedule deleted');
      closeModal(true);
    }
  }, [deleteResult]);

  const endSchedule = () => {
    runEnd({
      url: `schedule/end/${details.uniqueId}`,
    });
  };

  useEffect(() => {
    if (endResult) {
      successNotification('Schedule ended');
      closeModal(true);
    }
  }, [endResult]);

  const numberOfStudentsValidator = (_: any) => {
    if (!labBundle) {
      return Promise.reject(new Error('Please select Lab bundle!'));
    }
    if (numberOfStudents > maxNumberOfStudents) {
      return Promise.reject(
        new Error('Maximum number of students is ' + maxNumberOfStudents + '!')
      );
    }
    return Promise.resolve();
  };

  const prepareDatesForForm = (scheduleDates: Array<ScheduleDate>, timeByDay: boolean) => {
    let dates: any[] = [];
    if (scheduleDates) {
      if (timeByDay) {
        dates = scheduleDates.map((d: ScheduleDate) => {
          return {
            startDate: newDate(addTimezoneOnDate(d.startDate).toISOString()).utc(),
            endDate: newDate(addTimezoneOnDate(d.endDate).toISOString()).utc(),
          };
        });
      } else {
        const current = scheduleDates[0];
        if (current) {
          dates = [
            {
              startDate: newDate(addTimezoneOnDate(current.startDate).toISOString()).utc(),
              endDate: newDate(addTimezoneOnDate(current.endDate).toISOString()).utc(),
            },
          ];
        }
      }
    }
    return dates;
  };

  const onTimeByDayChange = useCallback(
    (checked: boolean) => {
      if (isEdit) {
        if (checked === details?.timeByDay) {
          form.setFieldsValue({
            dates: prepareDatesForForm(details.dates, checked),
          });
        } else {
          form.setFieldsValue({
            dates: [
              {
                startDate: newDate().startOf('day').set('hour', 8),
                endDate: newDate().startOf('day').set('hour', 16),
              },
            ],
          });
        }
      } else {
        if (startDate && endDate) {
          if (!checked) {
            form.setFieldsValue({
              dates: [
                {
                  startDate: newDate(startDate),
                  endDate: newDate(endDate),
                },
              ],
            });
          } else {
            form.setFieldsValue({
              dates: getDatesBetween(startDate, endDate).map((date: any) => {
                return {
                  startDate: date.set('hour', 8),
                  endDate: date.set('hour', 16),
                };
              }),
            });
          }
        }
      }
    },
    [form, isEdit, details?.dates, details?.timeByDay, details?.timezone, startDate, endDate]
  );

  const emailValidator = (rule: any, values: any, callback: any) => {
    if (!values) {
      callback();
      return;
    }

    const emailRegex = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
    const invalidInputs = values.filter((value: any) => !value.match(emailRegex));
    if (invalidInputs.length === 0) {
      callback();
    } else if (invalidInputs.length === 1) {
      callback(invalidInputs.join('') + ' is not a valid email');
    } else {
      callback(
        invalidInputs.slice(0, -1).join(', ') +
          ' and ' +
          invalidInputs.slice(-1) +
          ' are not valid emails'
      );
    }
  };

  return (
    <Modal
      title={isEdit ? 'Update scheduled lab' : 'Schedule a new lab'}
      visible={true}
      footer={null}
      centered
      closable={false}
      maskClosable={false}
      destroyOnClose
      width='1000px'>
      <Spin spinning={loadingDetails || loadingLabBundles || loadingMaxNumberOfStudents}>
        <Form
          form={form}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          labelAlign='left'
          onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                wrapperCol={{ span: 24 }}
                label=''
                name='labBundleId'
                colon={false}
                rules={[{ required: true, message: 'Please select lab bundle!' }]}>
                <Select
                  showSearch
                  filterOption={filterOption}
                  placeholder='Select Lab bundle'
                  onSelect={selectLabBundle}>
                  {lbOptions}
                </Select>
              </Form.Item>
              <Form.Item label='Custom name' name='customName' colon={false}>
                <Input />
              </Form.Item>

              <Form.Item
                label='Number of students'
                name='numberOfStudents'
                colon={false}
                rules={[{ required: true, validator: numberOfStudentsValidator }]}>
                <InputNumber min={1} onChange={changeNumberOfStudents} className='full-width' />
              </Form.Item>
              <Form.Item label='Students per workgroup' colon={false}>
                <span>{labBundle ? labBundle?.studentsPerWorkgroup : 0}</span>
              </Form.Item>
              <Form.Item label='Number of workgroups' name='workgroups' colon={false}>
                <span>{numberOfWorkgroups}</span>
              </Form.Item>
              <Form.Item
                label='Instructor'
                name='instructorId'
                colon={false}
                rules={[{ required: true, message: 'Please select instructor!' }]}>
                <Select
                  showSearch
                  filterOption={filterOption}
                  placeholder='Select instructor'
                  onSelect={selectInstructor}>
                  {instructorOptions}
                </Select>
              </Form.Item>
              <Form.Item
                label='Instructor email'
                name='instructorEmail'
                colon={false}
                rules={[{ required: true, type: 'email', message: 'Please enter email!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label='Turn on notifications'
                name='notificationEnabled'
                colon={false}
                initialValue={true}
                valuePropName='checked'>
                <Switch size='small' />
              </Form.Item>
              <Form.Item
                label='Customer email'
                name='customerEmails'
                colon={false}
                rules={[
                  {
                    validator: emailValidator,
                  },
                ]}>
                <Select mode='tags' open={false} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='Select Timezone'
                name='timezone'
                colon={false}
                initialValue={timezone}>
                <Select showSearch filterOption={filterOption}>
                  {timezones.map((zone: Timezone) => (
                    <Option value={zone.value} key={zone.value}>
                      {zone.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <TimeByDayForm form={form} details={details} onTimeByDayChange={onTimeByDayChange} />
            </Col>
          </Row>

          <Space className='flex-center margin-top-24'>
            <Button onClick={onCancel}>Cancel</Button>
            {!isEdit ? (
              <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                {() => (
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={loading}
                    disabled={
                      !form.isFieldsTouched(
                        ['labBundleId', 'numberOfStudents', 'instructorId'],
                        true
                      ) ||
                      form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                      loading
                    }>
                    Schedule
                  </Button>
                )}
              </Form.Item>
            ) : (
              <>
                <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={loading}
                    disabled={
                      !form.isFieldsTouched(
                        ['labBundleId', 'numberOfStudents', 'instructorId'],
                        true
                      ) ||
                      form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                      loading
                    }>
                    Update
                  </Button>
                </Form.Item>
                {isBetween(newDate(), details?.startDate, details?.endDate) ? (
                  <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                    <Popconfirm
                      placement='top'
                      title='Are you sure you want to end schedule now?'
                      icon={<StopOutlined style={{ color: 'red' }} />}
                      onConfirm={endSchedule}
                      okText='Yes'
                      cancelText='No'>
                      <Button type='primary' loading={loadingEnd} danger icon={<StopOutlined />}>
                        End
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                ) : (
                  <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                    <Popconfirm
                      placement='top'
                      title='Are you sure you want to delete scheduled lab?'
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={deleteSchedule}
                      okText='Yes'
                      cancelText='No'>
                      <Button
                        type='primary'
                        loading={loadingDelete}
                        danger
                        icon={<DeleteOutlined />}>
                        Delete
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </>
            )}
          </Space>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ScheduleNewLabModal;
