import { createSlice } from '@reduxjs/toolkit';
import { tableInitialState } from '../../../model/interfaces/shared/tableState';
import { ExerciseState } from '../../../model/interfaces/exercise';
import { reducers } from './reducers';
import { extraReducers } from './extraReducers';

const initialState: ExerciseState = {
  ...tableInitialState,
};

const exerciseSlice = createSlice({
  name: 'exercise',
  initialState,
  reducers,
  extraReducers,
});

export default exerciseSlice;

export const exerciseActions = exerciseSlice.actions;
