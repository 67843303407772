import { checkSortOrder, checkSortProperty } from '../../../util/helperUtils';
import { SIDeviceState } from '../../../model/interfaces/siDevice';

export const reducers = {
  setCurrentPage(state: SIDeviceState, action: any) {
    state.page.currentPage = action.payload.current > 0 ? action.payload.current - 1 : 0;
  },
  setRequest(state: SIDeviceState, action: any) {
    state.request = action.payload;
    state.page.currentPage = 0;
  },
  setPage(state: SIDeviceState, action: any) {
    const { page, sorter } = action.payload;
    state.page = {
      ...page,
      currentPage: 0,
      sortDirection: checkSortOrder(sorter.order),
      sortProperty: checkSortProperty(sorter.field),
    };
  },
};
