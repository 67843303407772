import { User } from './user';
import { Status } from '../enum/Status';

export interface AppState {
  user: User;
  status: Status;
  isLoading: boolean;
  token: string;
  timezone: string;
  timeFormat: TimeFormat;
  dateFormat: DateFormat;
  rdpConnection: RdpConnection;
  deviceStatus: any;
}

export interface Code {
  value: string;
  label: string;
}

export interface DeviceSetting {
  id: string;
  type: string;
  code: string;
  name: string;
}

export enum TimeFormat {
  H24 = '24h',
  H12 = '12h',
}

export enum DateFormat {
  DOT_SEPARATED = 'dd.mm.yy',
  SLASH_SEPARATED = 'mm/dd/yy',
  DASH_SEPARATED = 'yyyy-mm-dd',
}

export enum RdpConnection {
  RDP = 'rdp',
  HTML5 = 'html5',
}

export const TimezoneCookie = 'TIMEZONE_COOKIE';
export const TimeFormatCookie = 'TIME_FORMAT_COOKIE';
export const DateFormatCookie = 'DATE_FORMAT_COOKIE';
export const RdpConnectionCookie = 'RDP_CONNECTION_COOKIE';
export const Token = 'TOKEN';
