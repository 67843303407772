import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Exercise } from '../../../model/interfaces/exercise';
import useTimezone from '../../../hooks/useTimezone';
import { Button, Col, Form, Popconfirm, Progress, Row, Space, Table, Typography } from 'antd';
import ChangeDropdown from '../../../components/shared/ChangeDropdown';
import useAxios from '../../../hooks/useAxios';
import HttpMethod from '../../../model/enum/HttpMethod';
import useNotification from '../../../hooks/useNotification';
import { deviceSettingsCodes } from '../../../store/slices/codes/selectors';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '../../../components/shared/DatePicker/DatePicker';
import { getDate } from '../../../store/slices/schedule/selectors';
import { scheduleActions } from '../../../store/slices/schedule/state';
import { getProgressStatus } from '../../../util/helperUtils';
import { getDeviceStatus } from '../../../store/slices/app/selectors';
import { StopOutlined } from '@ant-design/icons';

const { Title } = Typography;

const InstructorLabDetails = () => {
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const [exercise, setExercise] = useState<any>({});
  const { formatUtcDate, addTimezoneOnDate, isBetween, newDate, datePickerFormat } = useTimezone();
  const date = useSelector(getDate);
  const { successNotification, warningNotification } = useNotification();
  const { result, loading, run } = useAxios();
  const { result: endResult, loading: loadingEnd, run: runEnd } = useAxios();
  const {
    result: changeResult,
    error: changeError,
    loading: changeLoading,
    run: changeExercise,
  } = useAxios();

  useEffect(() => {
    if (id) {
      refreshDetails();
    }
  }, [id]);

  const refreshDetails = () => {
    run({
      url: 'schedule/scheduled/' + id,
    });
  };

  useEffect(() => {
    if (changeResult) {
      setExercise({});
      successNotification('Exercise changed');
      refreshDetails();
    }
    if (changeError) {
      setExercise({});
    }
  }, [changeResult, changeError]);

  const columns = [
    {
      title: 'Workgroup',
      dataIndex: 'workgroupDescription',
      key: 'workgroupDescription',
    },
    {
      title: 'Exercise',
      dataIndex: 'exercise',
      key: 'exercise',
      render: (exerciseId: string) =>
        result?.exercises.find((ex: any) => ex.id === exerciseId)!.name,
    },
    {
      title: 'Start date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date: Date) => formatUtcDate(date),
    },
    {
      title: 'End date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date: Date) => formatUtcDate(date),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          <ChangeDropdown
            value={exercise.scheduleLabId === record.id ? exercise.exerciseId : null}
            confirmMessage={`Are you sure you want change exercise for ${record.workgroupDescription}?`}
            title='Change exercise on workgroup'
            data={result && result?.exercises}
            onChange={(value) => {
              onExerciseChange({
                scheduleLabId: record.id,
                ...value,
              });
            }}
            property='exerciseId'
          />
        </div>
      ),
    },
    {
      title: 'Lab access',
      dataIndex: 'lab-access',
      key: 'lab-access',
      render: (text: any, record: any) =>
        isLabActive(record) ? (
          <Button type='primary' key='details'>
            <Link to={`/home/lab-details/lab/${record.id}/access`}>Access Lab</Link>
          </Button>
        ) : (
          <Button
            type='primary'
            key='details'
            onClick={(event) => {
              warningNotification("Can't access Lab. Lab is not started yet!");
              event.stopPropagation();
            }}>
            Access Lab
          </Button>
        ),
    },
  ];

  const isLabActive = (scheduledLab: any) => {
    return newDate().isBetween(newDate(scheduledLab.startDate), newDate(scheduledLab.endDate));
  };

  const onExerciseChange = (values: any) => {
    setExercise(values);
    changeExercise({
      url: 'schedule/change-exercise',
      method: HttpMethod.POST,
      body: {
        scheduleId: id,
        uniqueId: result.uniqueId,
        ...values,
      },
    });
  };

  const dataSource = result?.labs
    .filter(
      (lab: any) =>
        !result.timeByDay ||
        addTimezoneOnDate(lab.startDate).format(datePickerFormat) ===
          date.format(datePickerFormat) ||
        addTimezoneOnDate(lab.endDate).format(datePickerFormat) === date.format(datePickerFormat)
    )
    .map((lab: any) => {
      return {
        ...lab,
        key: lab.id + result.labs.indexOf(lab),
      };
    });

  const endSchedule = () => {
    runEnd({
      url: `schedule/end/${result.uniqueId}`,
    });
  };

  useEffect(() => {
    if (endResult) {
      successNotification('Schedule ended');
      refreshDetails();
    }
  }, [endResult]);

  return (
    <>
      <Row>
        <Col span={24}>
          {result && (
            <Title level={4}>
              {result?.description} ({result?.code})
            </Title>
          )}
        </Col>
      </Row>

      <div className='flex-between m-b-8'>
        <div>
          {result?.timeByDay && (
            <DatePicker
              value={date}
              placeholder='Date'
              allowClear={false}
              onChange={(value: any) => dispatch(scheduleActions.setDate(value))}
            />
          )}
        </div>
        <Space>
          {isBetween(newDate(), result?.startDate, result?.endDate) && (
            <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
              <Popconfirm
                placement='left'
                title='Are you sure you want to end lab now?'
                icon={<StopOutlined style={{ color: 'red' }} />}
                onConfirm={endSchedule}
                okText='Yes'
                cancelText='No'>
                <Button type='primary' loading={loadingEnd} danger icon={<StopOutlined />}>
                  End lab
                </Button>
              </Popconfirm>
            </Form.Item>
          )}
          <ChangeDropdown
            value={exercise.scheduleLabId === null ? exercise.exerciseId : null}
            confirmMessage='Are you sure you want change exercise on all workgroups?'
            title='Change exercise on all workgroups'
            data={result?.exercises}
            onChange={(value) => {
              onExerciseChange({
                scheduleLabId: null,
                ...value,
              });
            }}
            property='exerciseId'
          />
        </Space>
      </div>

      <Table
        rowKey='key'
        loading={loading || changeLoading}
        columns={columns}
        dataSource={dataSource}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record: any) => (
            <ExpandableLabDetails
              dataSource={record.devices}
              scheduleId={id}
              scheduleLabId={record.id}
              uniqueId={result.uniqueId}
            />
          ),
        }}
        pagination={false}
        scroll={{ x: 300, scrollToFirstRowOnChange: true }}
      />
    </>
  );
};

const ExpandableLabDetails = ({
  dataSource,
  scheduleId,
  scheduleLabId,
  uniqueId,
}: {
  dataSource: any[];
  scheduleId: string;
  scheduleLabId: string;
  uniqueId: string;
}) => {
  const deviceSettings = useSelector(deviceSettingsCodes);
  const { successNotification } = useNotification();
  const { result, error, loading, run } = useAxios();
  const [setting, setSetting] = useState<any>({});
  const [data, setData] = useState(dataSource);
  const deviceStatus = useSelector(getDeviceStatus);

  useEffect(() => {
    if (result) {
      setSetting({});
      successNotification('Setting changed');
    }
    if (error) {
      setSetting({});
    }
  }, [result, error]);

  useEffect(() => {
    if (dataSource) {
      dataSource.forEach((d: any) => {
        if (deviceStatus[d.physicalDeviceName]) {
          d.progress = deviceStatus[d.physicalDeviceName].progress;
          d.status = deviceStatus[d.physicalDeviceName].status;
        }
      });
      setData([...dataSource]);
    }
  }, [dataSource, deviceStatus]);

  const onSettingsChange = (values: any) => {
    setSetting(values);
    run({
      url: 'schedule/change-device-setting',
      method: HttpMethod.POST,
      body: {
        uniqueId,
        scheduleId,
        scheduleLabId,
        ...values,
      },
    });
  };

  const getSettings = useCallback(
    (type: string) => {
      let array: any = [];
      const allSetting = deviceSettings['ALL'];
      const typeSettings = deviceSettings[type];
      if (allSetting != null) {
        array = [...array, ...allSetting];
      }
      if (typeSettings != null) {
        array = [...array, ...typeSettings];
      }
      return array;
    },
    [deviceSettings]
  );

  const columns = [
    {
      title: 'Exercise name',
      dataIndex: 'exerciseEquipmentName',
      key: 'exerciseEquipmentName',
    },
    {
      title: 'Logical name',
      dataIndex: 'logicalDeviceName',
      key: 'logicalDeviceName',
    },
    {
      title: 'Physical device name',
      dataIndex: 'physicalDeviceName',
      key: 'physicalDeviceName',
    },
    {
      title: 'Status',
      dataIndex: 'progress',
      key: 'progress',
      render: (text: number, record: any) => (
        <Progress
          percent={text === -1 ? 100 : Math.round(text)}
          size='small'
          status={getProgressStatus(record.status)}
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: any) => (
        <ChangeDropdown
          value={setting?.deviceId === record.physicalDeviceId ? setting.settingId : null}
          confirmMessage={`Are you sure you want change device settings for ${record.physicalDeviceName}?`}
          title='Change device settings'
          data={getSettings(record.physicalDeviceType)}
          property='settingId'
          onChange={(obj) =>
            onSettingsChange({
              deviceId: record.physicalDeviceId,
              deviceName: record.physicalDeviceName,
              ...obj,
            })
          }
        />
      ),
    },
  ];

  return (
    <>
      <Title level={5}>Individual device control</Title>
      <Table
        rowKey='id'
        loading={loading}
        columns={columns}
        dataSource={data}
        size='small'
        pagination={false}
        scroll={{ x: 300, scrollToFirstRowOnChange: true }}
      />
    </>
  );
};

export default InstructorLabDetails;
