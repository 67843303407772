import React, { useCallback, useEffect, useState } from 'react';
import { ScheduleStudent } from '../../../model/interfaces/student';
import { Progress, Table } from 'antd';
import ChangeDropdown from '../../../components/shared/ChangeDropdown';
import { useSelector } from 'react-redux';
import { deviceSettingsCodes } from '../../../store/slices/codes/selectors';
import HttpMethod from '../../../model/enum/HttpMethod';
import useAxios from '../../../hooks/useAxios';
import useNotification from '../../../hooks/useNotification';
import { getProgressStatus } from '../../../util/helperUtils';

interface DeviceControlTabProps {
  schedule: ScheduleStudent;
  isLoading: boolean;
  refresh: () => void;
}

const DeviceControlTab = ({ schedule, isLoading, refresh }: DeviceControlTabProps) => {
  const deviceSettings = useSelector(deviceSettingsCodes);
  const { successNotification } = useNotification();
  const { result, error, loading, run } = useAxios();
  const [setting, setSetting] = useState<any>({});

  useEffect(() => {
    if (result) {
      setSetting({});
      successNotification('Setting changed');
      refresh();
    }
    if (error) {
      setSetting({});
    }
  }, [result, error]);

  const onSettingsChange = (values: any) => {
    setSetting(values);
    run({
      url: 'schedule/change-device-setting',
      method: HttpMethod.POST,
      body: {
        uniqueId: schedule.uniqueId,
        scheduleId: schedule.scheduleId,
        scheduleLabId: schedule.lab.id,
        ...values,
      },
    });
  };

  const getSettings = useCallback(
    (type: string) => {
      let array: any = [];
      const allSetting = deviceSettings['ALL'];
      const typeSettings = deviceSettings[type];
      if (allSetting != null) {
        array = [...array, ...allSetting];
      }
      if (typeSettings != null) {
        array = [...array, ...typeSettings];
      }
      return array;
    },
    [deviceSettings]
  );

  const columns = [
    {
      title: 'Exercise name',
      dataIndex: 'exerciseEquipmentName',
      key: 'exerciseEquipmentName',
    },
    {
      title: 'Device name',
      dataIndex: 'physicalDeviceName',
      key: 'physicalDeviceName',
    },
    {
      title: 'Status',
      dataIndex: 'progress',
      key: 'progress',
      render: (text: number, record: any) => (
        <Progress
          percent={text === -1 ? 100 : Math.round(text)}
          size='small'
          status={getProgressStatus(record.status)}
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: any) => (
        <ChangeDropdown
          value={setting?.deviceId === record.physicalDeviceId ? setting.settingId : null}
          confirmMessage={`Are you sure you want change device settings for ${record.physicalDeviceName}?`}
          title='Change device settings'
          data={getSettings(record.physicalDeviceType)}
          property='settingId'
          onChange={(obj) =>
            onSettingsChange({
              deviceId: record.physicalDeviceId,
              deviceName: record.physicalDeviceName,
              ...obj,
            })
          }
        />
      ),
    },
  ];

  return (
    <Table
      rowKey='id'
      loading={isLoading || loading}
      columns={columns}
      dataSource={schedule?.lab?.devices}
      size='small'
      pagination={false}
      scroll={{ x: 300, scrollToFirstRowOnChange: true }}
    />
  );
};

export default DeviceControlTab;
