import { allRoles, Roles } from '../model/enum/Roles';
import Homepage from '../pages/Homepage/Homepage';
import Profile from '../pages/Profile';
import LabTopology from '../pages/LabTopology/LabTopology';
import NewLabTopology from '../pages/LabTopology/NewLabTopology';
import Lab from '../pages/Lab/Lab';
import UCDevice from '../pages/Device/UCDevice';
import SIDevice from '../pages/Device/SIDevice';
import NewUCDevice from '../pages/Device/NewUCDevice';
import NewSIDevice from '../pages/Device/NewSIDevice';
import Exercise from '../pages/Exercise/Exercise';
import EditExerciseEquipment from '../pages/Exercise/EditExerciseEquipment';
import NewExercise from '../pages/Exercise/NewExercise';
import NewLab from '../pages/Lab/NewLab';
import LabBundle from '../pages/LabBundle/LabBundle';
import NewLabBundle from '../pages/LabBundle/NewLabBundle';
import Availability from '../pages/Availability/Availability';
import InstructorLabDetails from '../pages/Homepage/Instructor/InstructorLabDetails';
import LabAccessPage from '../pages/Homepage/Student/LabAccessPage';
import DeviceType from '../pages/DeviceType/DeviceType';
import NewDeviceType from '../pages/DeviceType/NewDeviceType';
import Tokens from 'pages/Tokens/Tokens';
// import NewToken from 'pages/Tokens/NewToken';
import StudentTokens from 'pages/Homepage/Student/StudentTokens';

const defaultRoutes = [
  {
    path: '/home',
    component: Homepage,
  },
  {
    path: '/profile',
    component: Profile,
  },
];

const labAdminRoutes = [
  {
    path: '/device-type',
    component: DeviceType,
  },
  {
    path: '/device-type/new',
    component: NewDeviceType,
  },
  {
    path: '/device-type/edit/:id',
    component: NewDeviceType,
  },
  {
    path: '/lab-topology',
    component: LabTopology,
  },
  {
    path: '/lab-topology/new',
    component: NewLabTopology,
  },
  {
    path: '/lab-topology/edit/:id',
    component: NewLabTopology,
  },
  {
    path: '/lab',
    component: Lab,
  },
  {
    path: '/lab/new',
    component: NewLab,
  },
  {
    path: '/lab/edit/:id',
    component: NewLab,
  },
  {
    path: '/uc-device/',
    component: UCDevice,
  },
  {
    path: '/uc-device/new',
    component: NewUCDevice,
  },
  {
    path: '/uc-device/edit/:id',
    component: NewUCDevice,
  },
  {
    path: '/si-device/',
    component: SIDevice,
  },
  {
    path: '/si-device/new',
    component: NewSIDevice,
  },
  {
    path: '/si-device/edit/:id',
    component: NewSIDevice,
  },
  {
    path: '/exercise',
    component: Exercise,
  },
  {
    path: '/exercise/new',
    component: NewExercise,
  },
  {
    path: '/exercise/edit/:id',
    component: NewExercise,
  },
  {
    path: '/exercise/equipment/edit/:id',
    component: EditExerciseEquipment,
  },
  {
    path: '/lab-bundle',
    component: LabBundle,
  },
  {
    path: '/lab-bundle/new',
    component: NewLabBundle,
  },
  {
    path: '/lab-bundle/edit/:id',
    component: NewLabBundle,
  },
];

const schedulingAdminRoutes = [
  {
    path: '/availability',
    component: Availability,
  },
  {
    path: '/tokens',
    component: Tokens,
  },
  // {
  //   path: '/tokens/edit/:id',
  //   component: NewToken,
  // },
];

const studentRoutes = [
  {
    path: '/home/lab/:id/:tab',
    component: LabAccessPage,
  },
  {
    path: '/student-tokens',
    component: StudentTokens,
  },
];

const instructorAndLabAdminRoutes = [
  {
    path: '/home/lab-details/:id',
    component: InstructorLabDetails,
  },
  {
    path: '/home/lab-details/lab/:id/:tab',
    component: LabAccessPage,
  },
];

export const routes = [
  ...Object.values(defaultRoutes).map((entry) => ({ ...entry, roles: allRoles })),
  ...Object.values(labAdminRoutes).map((entry) => ({ ...entry, roles: [Roles.LAB_ADMIN] })),
  ...Object.values(schedulingAdminRoutes).map((entry) => ({
    ...entry,
    roles: [Roles.SCHEDULING_ADMIN],
  })),
  ...Object.values(studentRoutes).map((entry) => ({
    ...entry,
    roles: [Roles.STUDENT],
  })),
  ...Object.values(instructorAndLabAdminRoutes).map((entry) => ({
    ...entry,
    roles: [Roles.INSTRUCTOR, Roles.LAB_ADMIN],
  })),
];
