import { createAsyncThunk } from '@reduxjs/toolkit';
import { BasePageRequest } from '../../../model/interfaces/shared/basePageRequest';
import { LabBundleFilterRequest, LabBundleState } from '../../../model/interfaces/labBundle';
import { filterLabBundleApi } from '../../../service/LabBundleService';
import { Status } from '../../../model/enum/Status';

export const filterLabBundle = createAsyncThunk(
  'getLabBundle',
  async (command: BasePageRequest<LabBundleFilterRequest>) => {
    const response = await filterLabBundleApi(command);
    return response.data;
  }
);

export const extraReducers = (builder: any) => {
  builder.addCase(filterLabBundle.pending, (state: LabBundleState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(filterLabBundle.fulfilled, (state: LabBundleState, action: any) => {
    state.status = Status.SUCCESS;
    state.page = action.payload.page;
    state.data = action.payload.response;
  });
  builder.addCase(filterLabBundle.rejected, (state: LabBundleState) => {
    state.status = Status.ERROR;
    state.data = [];
  });
};
