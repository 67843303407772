import Keycloak from 'keycloak-js';

// eslint-disable-next-line new-cap
const keycloak = Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_AUTH,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT as string,
});

export default keycloak;
