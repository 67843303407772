import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Row, Select, Space, Spin, Switch, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import InputRemove from '../../components/shared/InputRemove';
import useAxios from '../../hooks/useAxios';
import HttpMethod from '../../model/enum/HttpMethod';
import useNotification from '../../hooks/useNotification';
import { Exercise } from '../../model/interfaces/exercise';
import { useKeycloak } from '@react-keycloak/web';
import { filterOption } from '../../util/helperUtils';

const { Option } = Select;
const { Title } = Typography;

const NewLabBundle = () => {
  const [form] = Form.useForm();
  const { id }: any = useParams();
  const { keycloak } = useKeycloak();
  const [totalTime, setTotalTime] = useState(0);
  const { successNotification } = useNotification();
  const { result, loading, run } = useAxios();
  const { result: details, loading: loadingDetails, run: getDetails } = useAxios();
  const { result: exercises, loading: loadingExercises, run: getExercises } = useAxios();
  const isEdit = !!id;

  useEffect(() => {
    getExercises({
      url: 'exercise/all',
    });
  }, []);

  useEffect(() => {
    if (isEdit) {
      getDetails({
        url: `lab-bundle/${id}`,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (details && exercises) {
      form.setFieldsValue(details);
      calculateTotalTime();
    }
  }, [details, exercises]);

  useEffect(() => {
    if (result) {
      if (isEdit) {
        successNotification('Lab bundle updated');
      } else {
        successNotification('Lab bundle created');
      }
      history.back();
    }
  }, [result]);

  const onFinish = (values: any) => {
    if (isEdit) {
      run({
        url: 'lab-bundle/update',
        method: HttpMethod.PUT,
        body: {
          id: isEdit ? id : null,
          ...details,
          ...values,
          authorId: keycloak.subject,
        },
      });
    } else {
      run({
        url: 'lab-bundle/create',
        method: HttpMethod.POST,
        body: {
          ...values,
          authorId: keycloak.subject,
        },
      });
    }
  };

  const exerciseOptions = exercises?.map((device: any) => {
    return (
      <Option value={device.id} key={device.id}>
        {device.name}
      </Option>
    );
  });

  const calculateTotalTime = () => {
    const selectedExercises = form.getFieldValue('exercises');

    const sum = selectedExercises.reduce((s: number, e: any) => {
      const ex = exercises.find((i: Exercise) => i.id === e?.exerciseId);
      if (ex) {
        return s + ex.durationMinutes;
      } else {
        return s;
      }
    }, 0);

    setTotalTime(sum);
  };

  return (
    <Spin spinning={loadingDetails || loading || loadingExercises}>
      <Card>
        <Form
          form={form}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          labelAlign='left'
          onFinish={onFinish}
          initialValues={{ exercises: [''] }}>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Title level={4}>Basic Data</Title>
              <Form.Item
                label='Code'
                name='code'
                colon={false}
                rules={[{ required: true, message: 'Please enter lab bundle code!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label='Description'
                name='description'
                colon={false}
                rules={[{ required: true, message: 'Please enter lab bundle description!' }]}>
                <Input />
              </Form.Item>
              <Form.Item label='Short Description' name='shortDescription' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='Hours per day' name='durationHoursPerDay' colon={false}>
                <Input type='number' min='1' max='24' />
              </Form.Item>
              <Form.Item label='Duration in days' name='durationDays' colon={false}>
                <Input type='number' min='1' />
              </Form.Item>
              <Form.Item
                label='Students per Workgroup'
                name='studentsPerWorkgroup'
                colon={false}
                rules={[{ required: true, message: 'Please enter the number of students!' }]}>
                <Input type='number' min='1' />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Title level={4}>ILT Lab Reservation System Data</Title>
              <Form.Item
                label='Reservation possible'
                name='reservationPossible'
                colon={false}
                valuePropName='checked'
                initialValue={false}>
                <Switch size='small' />
              </Form.Item>
              <Form.Item
                label='Is Production/Staging'
                name='production'
                colon={false}
                valuePropName='checked'
                initialValue={false}>
                <Switch size='small' />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={64}>
            <Col span={24}>
              <Title level={4}>Detailed Data</Title>
              <Form.Item
                label='Content'
                colon={false}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 12 }}>
                <Form.List name='exercises'>
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field: any) => (
                        <Row key={field.key} gutter={24}>
                          <Col span={24} md={12}>
                            <Form.Item
                              key={field.key}
                              colon={false}
                              name={[field.name, 'exerciseId']}
                              fieldKey={[field.fieldKey, 'exerciseId']}
                              rules={[{ required: true, message: 'Please choose an exercise!' }]}>
                              <Select
                                showSearch
                                placeholder='Select exercise'
                                onChange={calculateTotalTime}
                                loading={loadingExercises}
                                filterOption={filterOption}>
                                {exerciseOptions}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={24} md={12}>
                            <Form.Item>
                              <Form.Item
                                {...field}
                                className='m-b-8'
                                noStyle
                                name={[field.name, 'exerciseName']}
                                fieldKey={[field.fieldKey, 'exerciseName']}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: 'Please enter exercise name!',
                                  },
                                ]}>
                                <InputRemove
                                  placeholder='Exercise name'
                                  remove={(index: any) => {
                                    remove(index);
                                    calculateTotalTime();
                                  }}
                                  showRemove={fields.length > 1}
                                  name={field.name}
                                />
                              </Form.Item>
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                      <p>Total: {totalTime} minutes</p>
                      <Form.Item>
                        <Button type='dashed' block onClick={() => add()} icon={<PlusOutlined />}>
                          Add exercise
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Col>
          </Row>

          <Space className='flex-center margin-top-24'>
            <Button onClick={() => history.back()}>Cancel</Button>
            {!isEdit && (
              <Button type='primary' htmlType='submit'>
                Create
              </Button>
            )}
            {isEdit && (
              <Button type='primary' htmlType='submit'>
                Save
              </Button>
            )}
          </Space>
        </Form>
      </Card>
    </Spin>
  );
};

export default NewLabBundle;
