import React from 'react';
import './App.less';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import AntdConfigProvider from './providers/AntdConfigProvider';
import keycloak from './keycloak';
import { store } from './store';
import { Token } from './model/interfaces/app';

const App = () => {
  const onToken = (token: any) => {
    localStorage.setItem(Token, token);
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'login-required',
      }}
      onTokens={({ token }) => {
        onToken(token);
      }}>
      <AntdConfigProvider>
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </AntdConfigProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
