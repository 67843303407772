import React, { useEffect } from 'react';
import { Badge, Button, Space, Table, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { itemRender } from '../../../components/shared/TableItemRender';
import { Status } from '../../../model/enum/Status';
import { useDispatch, useSelector } from 'react-redux';
import useTimezone from '../../../hooks/useTimezone';
import {
  getCurrentPage,
  getData,
  getDate,
  getPage,
  getRequest,
  getStatus,
} from '../../../store/slices/schedule/selectors';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { TableActions } from '../../../model/enum/TableActions';
import { scheduleActions } from '../../../store/slices/schedule/state';
import Search from 'antd/es/input/Search';
import { filterScheduledLabs } from '../../../store/slices/schedule/extraReducers';
import { useKeycloak } from '@react-keycloak/web';
import DatePicker from '../../../components/shared/DatePicker/DatePicker';

const InstructorHomepage = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const { formatUtcDate } = useTimezone();
  const date = useSelector(getDate);
  const data = useSelector(getData);
  const page = useSelector(getPage);
  const status = useSelector(getStatus);
  const request = useSelector(getRequest);
  const currentPage = useSelector(getCurrentPage);

  useEffect(() => {
    fetchData();
  }, [request, currentPage, page.sortProperty, page.sortDirection, date]);

  const fetchData = () => {
    dispatch(
      filterScheduledLabs({
        request: {
          ...request,
          date,
          instructorId: keycloak.subject,
        },
        ...page,
      })
    );
  };

  const columns = [
    {
      title: 'Custom name',
      dataIndex: 'customName',
      key: 'customName',
    },
    {
      title: 'Lab',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Lab code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Start date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date: Date) => formatUtcDate(date),
    },
    {
      title: '',
      dataIndex: 'timeByDay',
      key: 'timeByDay',
      render: (timeByDay: boolean, record: any) =>
        timeByDay ? (
          <Tooltip title={`Time by day lab - ` + record?.dates?.length + ' day(s)'}>
            <Badge size='small' count={record?.dates?.length}>
              <CalendarOutlined />
            </Badge>
          </Tooltip>
        ) : (
          <Tooltip title='Full day lab'>
            <ClockCircleOutlined />
          </Tooltip>
        ),
    },
    {
      title: 'End date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date: Date) => formatUtcDate(date),
    },
    {
      title: 'Number of workgroups',
      dataIndex: 'numberOfWorkgroups',
      key: 'numberOfWorkgroups',
    },
    {
      title: '',
      dataIndex: 'details',
      key: 'details',
      render: (text: any, record: any) => (
        <Link to={`/home/lab-details/${record.id}`}>
          <Button type='primary'>Details</Button>
        </Link>
      ),
    },
  ];

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    switch (extra.action) {
      case TableActions.FILTER:
        dispatch(scheduleActions.setRequest(filters));
        break;
      case TableActions.SORT:
        dispatch(scheduleActions.setPage({ page, sorter }));
        break;
      case TableActions.PAGINATE:
        dispatch(scheduleActions.setCurrentPage(pagination));
        break;
    }
  };

  const onSearch = (search: string) => {
    dispatch(
      scheduleActions.setRequest({
        ...request,
        search,
      })
    );
  };

  return (
    <>
      <Space className='flex-start m-b-16'>
        <Search
          defaultValue={request?.search}
          placeholder='Search'
          className='max-w-300'
          allowClear
          enterButton
          onSearch={onSearch}
        />

        <DatePicker
          value={date}
          placeholder='Date'
          allowClear={false}
          onChange={(value: any) => dispatch(scheduleActions.setDate(value))}
        />
      </Space>
      <Table
        rowKey='id'
        columns={columns}
        dataSource={data}
        pagination={{
          position: ['bottomCenter'],
          size: 'small',
          itemRender,
          current: currentPage + 1,
          pageSize: page?.size,
          total: page?.totalElements,
          showSizeChanger: false,
        }}
        onChange={onChange}
        loading={status === Status.LOADING}
        scroll={{ x: 300, scrollToFirstRowOnChange: true }}
      />
    </>
  );
};

export default InstructorHomepage;
