import { LabTopologyState } from '../../../model/interfaces/labTopology';
import { checkSortOrder, checkSortProperty } from '../../../util/helperUtils';

export const reducers = {
  setCurrentPage(state: LabTopologyState, action: any) {
    state.page.currentPage = action.payload.current > 0 ? action.payload.current - 1 : 0;
  },
  setRequest(state: LabTopologyState, action: any) {
    state.request = action.payload;
    state.page.currentPage = 0;
  },
  setPage(state: LabTopologyState, action: any) {
    const { page, sorter } = action.payload;
    state.page = {
      ...page,
      currentPage: 0,
      sortDirection: checkSortOrder(sorter.order),
      sortProperty: checkSortProperty(sorter.field),
    };
  },
};
