import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Switch } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import DatePicker from './DatePicker/DatePicker';
import TimePicker from './TimePicker/TimePicker';
import { Schedule } from '../../model/interfaces/schedule';
import useTimezone from '../../hooks/useTimezone';

interface TimeByDay {
  form: any;
  details: Schedule;
  onTimeByDayChange: (timeByDay: boolean) => void;
}

const TimeByDayForm = ({ form, details, onTimeByDayChange }: TimeByDay) => {
  const { newDate } = useTimezone();
  const [timeByDay, setTimeByDay] = useState(false);

  useEffect(() => {
    if (details) {
      setTimeByDay(details.timeByDay);
    }
  }, [details]);

  const populateDate = () => {
    const dates = form.getFieldsValue(['dates']).dates;
    const prevDate = dates[dates.length - 2];
    dates[dates.length - 1] = {
      startDate: newDate(prevDate.startDate).add(1, 'day'),
      endDate: newDate(prevDate.endDate).add(1, 'day'),
    };
    form.setFieldsValue({ dates: dates });
  };

  const changeTimeByDay = (checked: boolean) => {
    onTimeByDayChange(checked);
    setTimeByDay(checked);
  };

  const updateEndDate = (index: any) => {
    let dates = form.getFieldsValue(['dates']).dates;
    let startDate = dates[index].startDate;
    let endDate = dates[index].endDate;

    let newEndDate = newDate()
      .set('year', startDate.get('year'))
      .set('month', startDate.get('month'))
      .set('date', startDate.get('date'))
      .set('hour', endDate.get('hour'))
      .set('minute', endDate.get('minute'))
      .set('second', 0);

    if (!timeByDay) {
      newEndDate = newDate(newEndDate).add(1, 'day');
    }

    dates[index].endDate = newEndDate;
    form.setFieldsValue({ dates });
  };

  return (
    <>
      <Form.Item
        label='Add time by day'
        name='timeByDay'
        colon={false}
        valuePropName='checked'
        initialValue={false}>
        <Switch size='small' onChange={changeTimeByDay} />
      </Form.Item>
      {!timeByDay ? (
        <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
          <Form.List name='dates'>
            {(fields) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }: any) => (
                  <div key={key}>
                    <Row gutter={24}>
                      <Col span={24} md={6}>
                        <Form.Item label='Start date' colon={false} wrapperCol={{ span: 0 }} />
                      </Col>
                      <Col span={24} sm={12} md={9}>
                        <Form.Item
                          {...restField}
                          name={[name, 'startDate']}
                          fieldKey={[fieldKey, 'startDate']}
                          rules={[
                            {
                              required: true,
                              message: 'Please choose start date!',
                            },
                          ]}>
                          <DatePicker
                            placeholder='Start date'
                            allowClear={false}
                            onChange={() => updateEndDate(name)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} sm={12} md={9}>
                        <Form.Item
                          {...restField}
                          name={[name, 'startDate']}
                          fieldKey={[fieldKey, 'startDate']}
                          rules={[
                            {
                              required: true,
                              message: 'Please choose start time!',
                            },
                          ]}>
                          <TimePicker placeholder='Start time' allowClear={false} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={24} md={6}>
                        <Form.Item label='End date' colon={false} wrapperCol={{ span: 0 }} />
                      </Col>
                      <Col span={24} sm={12} md={9}>
                        <Form.Item
                          {...restField}
                          name={[name, 'endDate']}
                          fieldKey={[fieldKey, 'endDate']}
                          rules={[
                            {
                              required: true,
                              message: 'Please choose end date!',
                            },
                          ]}>
                          <DatePicker placeholder='End date' allowClear={false} />
                        </Form.Item>
                      </Col>
                      <Col span={24} sm={12} md={9}>
                        <Form.Item
                          {...restField}
                          name={[name, 'endDate']}
                          fieldKey={[fieldKey, 'endDate']}
                          rules={[
                            {
                              required: true,
                              message: 'Please choose end time!',
                            },
                          ]}>
                          <TimePicker placeholder='End time' allowClear={false} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </Form.Item>
      ) : (
        <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
          <Form.List name='dates'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }: any) => (
                  <Row key={key} gutter={12}>
                    <Col span={24} md={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'startDate']}
                        fieldKey={[fieldKey, 'startDate']}
                        rules={[{ required: true, message: 'Please choose start date!' }]}>
                        <DatePicker allowClear={false} onChange={() => updateEndDate(name)} />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'startDate']}
                        fieldKey={[fieldKey, 'startDate']}
                        rules={[{ required: true, message: 'Please choose start time!' }]}>
                        <TimePicker
                          placeholder='Start time'
                          className='full-width'
                          allowClear={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={8}>
                      <div className='remove-row m-0'>
                        <Form.Item
                          className='full-width'
                          {...restField}
                          name={[name, 'endDate']}
                          fieldKey={[fieldKey, 'endDate']}
                          rules={[
                            {
                              required: true,
                              message: 'Please choose end time!',
                            },
                          ]}>
                          <TimePicker
                            placeholder='End time'
                            className='full-width'
                            allowClear={false}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <Button
                            onClick={() => {
                              remove(name);
                              form.validateFields(['dates']);
                            }}
                            icon={<DeleteOutlined />}
                          />
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                ))}
                <Button
                  type='dashed'
                  onClick={() => {
                    add();
                    populateDate();
                  }}
                  block
                  icon={<PlusOutlined />}
                />
              </>
            )}
          </Form.List>
        </Form.Item>
      )}
    </>
  );
};

export default TimeByDayForm;
