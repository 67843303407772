import React, { useEffect } from 'react';
import { Button, Card, Col, Form, Input, Row, Space, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import HttpMethod from '../../model/enum/HttpMethod';
import useNotification from '../../hooks/useNotification';

const NewDeviceType = () => {
  const [form] = Form.useForm();
  const { id }: any = useParams();
  const { successNotification } = useNotification();
  const { result, loading, run } = useAxios();
  const { result: details, loading: loadingDetails, run: getDetails } = useAxios();
  const isEdit = !!id;

  useEffect(() => {
    if (isEdit) {
      getDetails({
        url: `device-type/${id}`,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (details) {
      form.setFieldsValue(details);
    }
  }, [details]);

  useEffect(() => {
    if (result) {
      if (isEdit) {
        successNotification('Device type updated');
      } else {
        successNotification('Device type created');
      }
      history.back();
    }
  }, [result]);

  const onFinish = (values: any) => {
    if (isEdit) {
      run({
        url: 'device-type/update',
        method: HttpMethod.PUT,
        body: {
          id: isEdit ? id : null,
          ...details,
          ...values,
        },
      });
    } else {
      run({
        url: 'device-type/create',
        method: HttpMethod.POST,
        body: values,
      });
    }
  };

  return (
    <Spin spinning={loadingDetails || loading}>
      <Card>
        <Form
          form={form}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          labelAlign='left'
          onFinish={onFinish}
          initialValues={{ logicalDevices: [''] }}>
          <Row gutter={64}>
            <Col span={24} md={{ span: 16, offset: 4 }}>
              <Form.Item
                label='Code'
                name='code'
                colon={false}
                rules={[{ required: true, message: 'Please enter device type code!' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Space className='flex-center margin-top-24'>
            <Button onClick={() => history.back()}>Cancel</Button>
            {!isEdit && (
              <Button type='primary' htmlType='submit'>
                Create
              </Button>
            )}
            {isEdit && (
              <Button type='primary' htmlType='submit'>
                Save
              </Button>
            )}
          </Space>
        </Form>
      </Card>
    </Spin>
  );
};

export default NewDeviceType;
