import { createSlice } from '@reduxjs/toolkit';
import { AppState, DateFormat, RdpConnection, TimeFormat } from '../../../model/interfaces/app';
import { Status } from '../../../model/enum/Status';
import { reducers } from './reducers';
import { extraReducers } from './extraReducers';

export const initialState: AppState = {
  user: null,
  status: Status.INITIAL,
  isLoading: false,
  token: null,
  timezone: null,
  timeFormat: TimeFormat.H24,
  dateFormat: DateFormat.DOT_SEPARATED,
  rdpConnection: RdpConnection.RDP,
  deviceStatus: {},
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers,
  extraReducers,
});

export default appSlice;

export const appActions = appSlice.actions;
