import React, { useEffect, useState } from 'react';
import { Button, Card, Select, Space, Spin } from 'antd';
import { Calendar } from '../../../components/shared/Calendar/Calendar';
import { useDispatch, useSelector } from 'react-redux';
import { instructorsCodes, labBundlesCodes } from '../../../store/slices/codes/selectors';
import { getInstructorsCodes, getLabBundlesCodes } from '../../../store/slices/codes/extraReducers';
import ScheduleNewLabModal from '../../../components/modal/ScheduleNewLabModal';
import { filterOption, getLabBundleColor, getTextColor } from '../../../util/helperUtils';
import useAxios from '../../../hooks/useAxios';
import HttpMethod from '../../../model/enum/HttpMethod';
import useTimezone from '../../../hooks/useTimezone';

const { Option } = Select;

const SchedulingHomepage = () => {
  const dispatch = useDispatch();
  const { addTimezoneOnDate, newDate } = useTimezone();
  const [showModal, setShowModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [labBundleId, setLabBundleId] = useState();
  const [instructor, setInstructor] = useState();
  const [scheduleId, setScheduleId] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateUntil, setDateUntil] = useState();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const labBundles = useSelector(labBundlesCodes);
  const instructors = useSelector(instructorsCodes);
  const { result, loading, run } = useAxios();

  useEffect(() => {
    dispatch(getLabBundlesCodes());
    dispatch(getInstructorsCodes());
  }, []);

  useEffect(() => {
    if (result) {
      const preparedEvents = result.map((e: any) => {
        return {
          ...e,
          start: addTimezoneOnDate(e.start).toISOString(),
          end: addTimezoneOnDate(e.end).toISOString(),
          title: (e.customName ? `${e.title} (${e.customName})` : e.title) + ' - WG' + e.workgroup,
          color: getLabBundleColor(e.labBundleId),
          textColor: getTextColor(getLabBundleColor(e.labBundleId)),
        };
      });
      setEvents(preparedEvents);
    }
  }, [result]);

  useEffect(() => {
    if (dateFrom && dateUntil) {
      refreshCalendar();
    }
  }, [dateFrom, dateUntil, labBundleId, instructor]);

  const refreshCalendar = () => {
    // if (labBundleId) {
    run({
      url: 'schedule/calendar',
      method: HttpMethod.POST,
      body: {
        labBundleId,
        dateFrom,
        dateUntil,
        instructorId: instructor,
      },
    });
    // }
  };

  const onEventClick = (scheduleId: any) => {
    setScheduleId(scheduleId);
    setShowModal(true);
  };

  const onDatesChange = (info: any) => {
    setDateFrom(info.view.currentStart.toISOString());
    setDateUntil(info.view.currentEnd.toISOString());
  };

  const openModal = () => {
    setStartDate(newDate().startOf('day').toISOString());
    setEndDate(newDate().add(1, 'day').startOf('day').toISOString());
    setShowModal(true);
  };

  const closeModal = (refresh: boolean) => {
    setShowModal(false);
    setScheduleId(null);
    if (refresh) {
      refreshCalendar();
    }
  };

  const selectLabBundle = (id: any) => {
    setLabBundleId(id);
  };

  const labBundlesOptions = labBundles?.map((lb: any) => {
    return (
      <Option key={lb.value} value={lb.value}>
        {lb.label}
      </Option>
    );
  });

  const instructorOptions = instructors.map((i: any) => {
    if (events && events.map((e: any) => e.instructorId).includes(i.id)) {
      return (
        <Option key={i.id} value={i.id}>
          {`${i.firstName} ${i.lastName}`}
        </Option>
      );
    }
    return null;
  });

  return (
    <>
      {showModal && (
        <ScheduleNewLabModal
          labBundleId={labBundleId}
          id={scheduleId}
          closeModal={closeModal}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      <Card className='filter-card m-b-8'>
        <div className='flex-between flex-wrap gap-8'>
          <Space>
            <Select
              style={{ minWidth: 180 }}
              showSearch
              allowClear
              placeholder='Select lab bundle'
              onSelect={selectLabBundle}
              onClear={() => {
                setLabBundleId(null);
              }}
              value={labBundleId}
              filterOption={filterOption}>
              {labBundlesOptions}
            </Select>
            <Select
              style={{ minWidth: 180 }}
              showSearch
              allowClear
              placeholder='Select instructor'
              onSelect={(value: any) => setInstructor(value)}
              onClear={() => {
                setInstructor(null);
              }}
              value={instructor}
              filterOption={filterOption}>
              {instructorOptions}
            </Select>
          </Space>
          <Space>
            <Button onClick={openModal} type='primary'>
              Schedule a new lab
            </Button>
          </Space>
        </div>
      </Card>
      <Card>
        <Spin spinning={loading}>
          <div className='calendar-container'>
            <Calendar
              events={events}
              eventClick={(eventInfo: any) => onEventClick(eventInfo.event.id)}
              datesSet={onDatesChange}
            />
          </div>
        </Spin>
      </Card>
    </>
  );
};

export default SchedulingHomepage;
