import React, { useEffect } from 'react';
import {
  AutoComplete,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import useNotification from '../../hooks/useNotification';
import useAxios from '../../hooks/useAxios';
import HttpMethod from '../../model/enum/HttpMethod';
import { useDispatch, useSelector } from 'react-redux';
import { deviceTypeCodes, siDevicesCodes } from '../../store/slices/codes/selectors';
import { getDeviceTypeCodes, getSIDevicesCodes } from '../../store/slices/codes/extraReducers';
import InputRemove from '../../components/shared/InputRemove';
import { filterOption } from '../../util/helperUtils';

const { Option } = AutoComplete;
const { Title } = Typography;

const NewUCDevice = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id }: any = useParams();
  const { successNotification } = useNotification();
  const { result, loading, run } = useAxios();
  const { result: details, loading: loadingDetails, run: getDetails } = useAxios();
  const siDevices = useSelector(siDevicesCodes);
  const deviceTypes = useSelector(deviceTypeCodes);
  const isEdit = !!id;

  useEffect(() => {
    dispatch(getSIDevicesCodes());
    dispatch(getDeviceTypeCodes());
  }, []);

  useEffect(() => {
    if (isEdit) {
      getDetails({
        url: `uc-device/${id}`,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (details) {
      form.setFieldsValue({
        ...details,
        typeId: details.type.id,
      });
    }
  }, [details]);

  useEffect(() => {
    if (result) {
      if (isEdit) {
        successNotification('Device updated');
      } else {
        successNotification('Device created');
      }
      history.back();
    }
  }, [result]);

  const onFinish = (values: any) => {
    if (isEdit) {
      run({
        url: 'uc-device/update',
        method: HttpMethod.PUT,
        body: {
          id: isEdit ? id : null,
          ...details,
          ...values,
        },
      });
    } else {
      run({
        url: 'uc-device/create',
        method: HttpMethod.POST,
        body: values,
      });
    }
  };

  const siDevicesOptions = siDevices?.map((device: any) => {
    return (
      <Option value={device.label} key={device.value}>
        {device.label}
      </Option>
    );
  });

  const deviceTypesOptions = deviceTypes?.map((type: any) => {
    return (
      <Option value={type.value} key={type.value}>
        {type.label}
      </Option>
    );
  });

  return (
    <Spin spinning={loading || loadingDetails}>
      <Card>
        <Form form={form} labelCol={{ span: 12 }} labelAlign='left' onFinish={onFinish}>
          <Title level={4}>Basic Data</Title>
          <Row gutter={64}>
            <Col span={24} md={{ span: 12 }}>
              <Form.Item
                label='Name'
                name='name'
                colon={false}
                rules={[{ required: true, message: 'Please enter name!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label='Type'
                name='typeId'
                colon={false}
                rules={[{ required: true, message: 'Please select device type!' }]}>
                <Select showSearch filterOption={filterOption} placeholder='Select device type'>
                  {deviceTypesOptions}
                </Select>
              </Form.Item>
              <Form.Item label='Console Host' name='consoleHost' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Console Line' name='consoleLine' colon={false}>
                <Input type='number' min='1' />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label='Extra Line name' name='consoleExtraLineName' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Console Port' name='consolePort' colon={false}>
                <Input type='number' min='1' />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label='Access TS Vrf' name='accessTsVrf' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Low Port' name='lowPort' colon={false}>
                <Input type='number' min='1' />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label='High Port' name='highPort' colon={false}>
                <Input type='number' min='1' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Virtual Center' name='virtualCenter' colon={false}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label='ESX Host' name='esxHost' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Clean Priority' name='cleanPriority' colon={false}>
                <Input type='number' min='1' />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label='Load Priority' name='loadPriority' colon={false}>
                <Input type='number' min='1' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Management IP' name='managementIp' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Default iOS' name='defaultIos' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Default Configuration' name='defaultConfiguration' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Username' name='username' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Access Password' name='accessPassword' colon={false}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label='Enable Password' name='enablePassword' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Comment' name='comment' colon={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Title level={4}>Connections</Title>
          <Row>
            <Col span={24}>
              <Form.Item>
                <Form.List name='connections'>
                  {(fields: any, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field: any) => (
                        <Row gutter={24} key={field.key}>
                          <Col xs={12} sm={12} md={8}>
                            <Form.Item
                              colon={false}
                              name={[field.name, 'sourceInterface']}
                              fieldKey={[field.fieldKey, 'sourceInterface']}>
                              <Input placeholder='Source interface' />
                            </Form.Item>
                          </Col>

                          <Col xs={12} sm={12} md={8}>
                            <Form.Item
                              colon={false}
                              name={[field.name, 'destinationDevice']}
                              fieldKey={[field.fieldKey, 'destinationDevice']}>
                              <AutoComplete
                                allowClear
                                showSearch
                                placeholder='Destination device'
                                filterOption={filterOption}>
                                {siDevicesOptions}
                              </AutoComplete>
                            </Form.Item>
                          </Col>

                          <Col xs={12} sm={12} md={8}>
                            <Form.Item
                              colon={false}
                              name={[field.name, 'destinationInterface']}
                              fieldKey={[field.fieldKey, 'destinationInterface']}>
                              <InputRemove
                                placeholder='Destination interface'
                                remove={remove}
                                showRemove={fields.length > 0}
                                name={field.name}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button type='dashed' block onClick={() => add()} icon={<PlusOutlined />}>
                          Add connection
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Col>
          </Row>
          <Space className='flex-center margin-top-24'>
            <Button onClick={() => history.back()}>Cancel</Button>
            {!isEdit && (
              <Button type='primary' htmlType='submit'>
                Create
              </Button>
            )}
            {isEdit && (
              <Button type='primary' htmlType='submit'>
                Save
              </Button>
            )}
          </Space>
        </Form>
      </Card>
    </Spin>
  );
};

export default NewUCDevice;
