import React, { useEffect } from 'react';
import { Button, Card, Checkbox, Col, Form, Input, Mentions, Row, Space, Spin, Switch } from 'antd';
import { useParams } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import HttpMethod from '../../model/enum/HttpMethod';
import useNotification from '../../hooks/useNotification';

const { Option } = Mentions;

const EditExerciseEquipment = () => {
  const [form] = Form.useForm();
  const { id }: any = useParams();
  const { successNotification } = useNotification();
  const { result, loading, run } = useAxios();
  const { result: details, loading: loadingDetails, run: getDetails } = useAxios();

  useEffect(() => {
    if (id) {
      getDetails({
        url: `exercise/equipment/${id}`,
      });
    }
  }, [id]);

  useEffect(() => {
    if (details) {
      form.setFieldsValue(details);
    }
  }, [details]);

  useEffect(() => {
    if (result) {
      successNotification('Exercise equipment updated');
      history.back();
    }
  }, [result]);

  const onFinish = (values: any) => {
    run({
      url: 'exercise/equipment/update',
      method: HttpMethod.PUT,
      body: {
        id,
        ...details,
        ...values,
      },
    });
  };

  return (
    <Spin spinning={loadingDetails || loading}>
      <Card>
        <Form
          form={form}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          labelAlign='left'
          onFinish={onFinish}>
          <Row gutter={64}>
            <Col span={24} md={12}>
              <h3 className='bold'>Basic Configuration data</h3>
              <Form.Item label='Configuration for device' colon={false} name='logicalName'>
                <Input disabled />
              </Form.Item>
              <Form.Item label='Exercise Device Name' colon={false} name='exerciseDeviceName'>
                <Input />
              </Form.Item>
              <Form.Item
                label='Device is Virtual PC'
                colon={false}
                valuePropName='checked'
                name='virtualPc'>
                <Switch size='small' />
              </Form.Item>
              <Form.Item
                label='Device is TTY device'
                colon={false}
                valuePropName='checked'
                name='ttyDevice'>
                <Switch size='small' />
              </Form.Item>

              <h3 className='bold'>Redirect RDP sound</h3>
              <Form.Item
                labelCol={{ span: 21 }}
                colon={false}
                valuePropName='checked'
                name='redirectSound'
                label='Redirect remote sounds to the client'>
                <Switch size='small' />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 21 }}
                colon={false}
                valuePropName='checked'
                name='soundOnRemote'
                label='Play sounds at the remote computer'>
                <Switch size='small' />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 21 }}
                colon={false}
                valuePropName='checked'
                name='disableSound'
                label='Disable sound redirection'>
                <Switch size='small' />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <h3 className='bold'>Configuration</h3>
              <Form.Item name='configuration' colon={false} wrapperCol={{ span: 24 }}>
                <Mentions rows={12}>
                  <Option value='WG@'>@WG@</Option>
                  <Option value='0WG@'>@0WG@</Option>
                  <Option value='MGMTIP@'>@MGMTIP@</Option>
                </Mentions>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={64}>
            <Col span={24} md={12}>
              <Form.Item label='Type of Access to PC' colon={false} className='flex-checkboxes'>
                <Form.Item colon={false} valuePropName='checked' name='rdp'>
                  <Checkbox>RDP</Checkbox>
                </Form.Item>
                <Form.Item colon={false} valuePropName='checked' name='telnet'>
                  <Checkbox>Telnet</Checkbox>
                </Form.Item>
                <Form.Item colon={false} valuePropName='checked' name='ssh'>
                  <Checkbox>SSH</Checkbox>
                </Form.Item>
                <Form.Item colon={false} valuePropName='checked' name='https'>
                  <Checkbox>HTTPS</Checkbox>
                </Form.Item>
                <Form.Item colon={false} valuePropName='checked' name='http'>
                  <Checkbox>HTTP</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Checkbox>None</Checkbox>
                </Form.Item>
              </Form.Item>
              <Form.Item label='Access IP' colon={false} name='accessIp'>
                <Input />
              </Form.Item>
              <Form.Item label='Access Port' colon={false} name='accessPort'>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label='Login Username' colon={false} name='username'>
                <Input />
              </Form.Item>
              <Form.Item label='Login Password' colon={false} name='password'>
                <Input />
              </Form.Item>
              <Form.Item label='Enable Password' name='enablePassword' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='Login domain' colon={false} name='domain'>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Space className='flex-center margin-top-24'>
            <Button onClick={() => history.back()}>Cancel</Button>
            <Button type='primary' htmlType='submit'>
              Update
            </Button>
          </Space>
        </Form>
      </Card>
    </Spin>
  );
};

export default EditExerciseEquipment;
