import { createSlice } from '@reduxjs/toolkit';
import { tableInitialState } from '../../../model/interfaces/shared/tableState';
import { DeviceTypeState } from '../../../model/interfaces/deviceType';
import { reducers } from './reducers';
import { extraReducers } from './extraReducers';

const initialState: DeviceTypeState = {
  ...tableInitialState,
};

const deviceTypeSlice = createSlice({
  name: 'device-type',
  initialState,
  reducers,
  extraReducers,
});

export default deviceTypeSlice;

export const deviceTypeActions = deviceTypeSlice.actions;
