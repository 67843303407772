import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import * as React from 'react';
import useTimezone from '../../../hooks/useTimezone';

export const DayjsDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const DatePicker = (props: any) => {
  const { datePickerFormat } = useTimezone();

  return <DayjsDatePicker format={datePickerFormat} {...props} />;
};

export default DatePicker;
