import { useKeycloak } from '@react-keycloak/web';

const useHelper = () => {
  const { keycloak } = useKeycloak();

  const isAuthorized = (roles: string[]) => {
    if (keycloak && roles) {
      return roles.some((r) => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };

  return {
    isAuthorized,
  };
};

export default useHelper;
