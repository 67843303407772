import { createSlice } from '@reduxjs/toolkit';
import { tableInitialState } from '../../../model/interfaces/shared/tableState';
import { LabBundleState } from '../../../model/interfaces/labBundle';
import { reducers } from './reducers';
import { extraReducers } from './extraReducers';

const initialState: LabBundleState = {
  ...tableInitialState,
};

const labBundleSlice = createSlice({
  name: 'labBundle',
  initialState,
  reducers,
  extraReducers,
});

export default labBundleSlice;

export const labBundleActions = labBundleSlice.actions;
