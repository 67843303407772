import { createAsyncThunk } from '@reduxjs/toolkit';
import { BasePageRequest } from '../../../model/interfaces/shared/basePageRequest';
import { ExerciseFilterRequest, ExerciseState } from '../../../model/interfaces/exercise';
import { filterExerciseApi } from '../../../service/ExerciseService';
import { Status } from '../../../model/enum/Status';

export const filterExercise = createAsyncThunk(
  'getExercise',
  async (command: BasePageRequest<ExerciseFilterRequest>) => {
    const response = await filterExerciseApi(command);
    return response.data;
  }
);

export const extraReducers = (builder: any) => {
  builder.addCase(filterExercise.pending, (state: ExerciseState) => {
    state.status = Status.LOADING;
  });
  builder.addCase(filterExercise.fulfilled, (state: ExerciseState, action: any) => {
    state.status = Status.SUCCESS;
    state.page = action.payload.page;
    state.data = action.payload.response;
  });
  builder.addCase(filterExercise.rejected, (state: ExerciseState) => {
    state.status = Status.ERROR;
    state.data = [];
  });
};
