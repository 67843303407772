import { createSlice } from '@reduxjs/toolkit';
import { tableInitialState } from '../../../model/interfaces/shared/tableState';
import { ScheduleState } from '../../../model/interfaces/schedule';
import { reducers } from './reducers';
import { extraReducers } from './extraReducers';

const initialState: ScheduleState = {
  ...tableInitialState,
  date: null,
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers,
  extraReducers,
});

export default scheduleSlice;

export const scheduleActions = scheduleSlice.actions;
