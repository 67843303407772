import { checkSortOrder, checkSortProperty } from '../../../util/helperUtils';
import { ScheduleState } from '../../../model/interfaces/schedule';

export const reducers = {
  setCurrentPage(state: ScheduleState, action: any) {
    state.page.currentPage = action.payload.current > 0 ? action.payload.current - 1 : 0;
  },
  setRequest(state: ScheduleState, action: any) {
    state.request = action.payload;
    state.page.currentPage = 0;
  },
  setPage(state: ScheduleState, action: any) {
    const { page, sorter } = action.payload;
    state.page = {
      ...page,
      currentPage: 0,
      sortDirection: checkSortOrder(sorter.order),
      sortProperty: checkSortProperty(sorter.field),
    };
  },
  setDate(state: ScheduleState, action: any) {
    state.date = action.payload;
  },
};
